// ##################################################
// Main Fonts
// ##################################################

$font_primary: 'SkodaNext', Helvetica, Arial, sans-serif;


// ##################################################
// Colors
// ##################################################

// Moved to config file
$cgp_white: #fff;
$cgp_black: #000;
$cgp_black_2: #161718;
$cgp_brown: #333;
$cgp_brown_2: #888;

// Different shades of green, but very similar:
// #4ba82e, #47ab23, #4ba92f, #53a71a, #469d2c

// Main CGP colors
$cgp_primary: #419468;
$cgp_secondary: #78FAAE;
$cgp_button_color: #0E3A2F;

$cgp_gray:              #979797;
$cgp_gray_light:        #cccccc;
$cgp_gray_extra_light:  #ececec;
$cgp_gray_button_text:  #00000042;
$cgp_gray_button_bg:  #d8d8d8;
$cgp_gray_button_secondary:  #464748;
$cgp_gray_button_secondary_hover:  #5a5b5c;

// Other colours
$body_background:   #231f20;
$validation_failed: #f00;


// ##################################################
// Paths
// ##################################################

// Img path
// $img_path:              '../img/';



// ##################################################
// Z-index
// ##################################################

// $zindex_site_loader:	101;
// $zindex_header:         100;
// $zindex_cookie_notice:  998;
// $zindex_menu_more:      101;
// $zindex_modal:          999;
// $zindex_slider_arrows:  89;



// ##################################################
// Margins
// ##################################################

// Breakpoint margins
// $el_margin_bottom_xl:   40px;
// $el_margin_bottom_lg:   32px;
// $el_margin_bottom_md:   24px;
// $el_margin_bottom_sm:   24px;
// $el_margin_bottom_xs:   16px;

// // Section maargins
// $section_margin_xl:     160px;
// $section_margin_lg:     120px;
// $section_margin_md:     80px;
// $section_margin_sm:     60px;
// $section_margin_xs:     80px;



// ##################################################
// Class names (read from config JSON)
// ##################################################

// Usage: body.#{$class_open_menu} {};

// $class_open_menu:               map-get($class_names, class_open_menu);
// $class_open_search:             map-get($class_names, class_open_search);
// $class_open_submenu:            map-get($class_names, class_open_search);
// $class_visible:                 map-get($class_names, class_visible);
// $class_hidden:                  map-get($class_names, class_hidden);

// $class_media_playing:           map-get($class_names, class_media_playing);
// $class_active:                  map-get($class_names, class_active);
// $class_selected:                map-get($class_names, class_selected);
// $class_open:                    map-get($class_names, class_open);
// $class_mobile:                  map-get($class_names, class_mobile);
// $class_modal_open:              map-get($class_names, class_modal_open);
// $class_validation_failed:       map-get($class_names, class_validation_failed);



// ##################################################
// Transitions & easings
// ##################################################

// Easings
// $easing1: cubic-bezier(0.455, 0.03, 0.515, 0.955);
// $easingType1: cubic-bezier(0.46, 0.03, 0.52, 0.96);
// $easingType2: cubic-bezier(0.19, 1, 0.22, 1);


// // Transitions
// $transition_duration: 250ms;
// $transition_easing:   linear;

// $transition_easing_fadeup:         cubic-bezier(0.46, 0.03, 0.52, 0.96);
// $transition_easing_fadeLeftRight:  cubic-bezier(0.19, 1, 0.22, 1);
