.m-b {
margin-bottom: 15px;
}

.paket-bg {
// background: url(../img/banner-family-bg.png) no-repeat scroll center bottom transparent;
background-size: cover;
main {
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
	opacity: 1;
	&.animate {
	opacity: 0.01;
	}
}
.paketi-page {
	color: $cgp_white;
	padding: 50px 0;
}
}

#main .paket-bg .paketi-page h2 {
	font-size: 72px;
	line-height: 100%;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
}

.paket-bg .paketi-page {
p {
	text-align: center;
	font-size: 30px;
	line-height: 40px;
	font-weight: 400;
}
span.txt-small {
	font-size: 27px;
}
p small {
	text-transform: uppercase;
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
}
}

/* smalscreen */
@media screen and (max-width: 767px) {
#main .paket-bg .paketi-page h2 {
	font-size: 48px;
}
.paket-bg .paketi-page {
	p {
	font-size: 20px;
	line-height: 22px;
	}
	span.txt-small {
	font-size: 19px;
	}
	p small {
	font-size: 12px;
	}
}
}

/* SEZNAM - 1st page */

.paketi-page {
.paketi-list {
	padding: 30px 90px;
	border-top: 1px solid #5d5d5d;
	border-bottom: 1px solid #5d5d5d;
	.revija {
	border: 1px solid #5d5d5d;
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	margin-bottom: 15px;
	&.active, &:hover {
		border-color: $cgp_white;
	}
	.img {
		padding: 20px 20px;
		img {
		margin: 0 auto;
		}
	}
	.sub {
		border-top: 1px solid #5d5d5d;
		padding: 10px;
		padding-right: 55px;
		padding-left: 10px;
	}
	&.active .sub, &:hover .sub {
		border-color: $cgp_white;
		background-color: $cgp_primary;
	}
	.sub:before {
		content: "";
		position: absolute;
		right: 30px;
		width: 30px;
		height: 30px;
		border: 1px solid #5d5d5d;
		margin-top: 5px;
	}
	&:hover .sub:before {
		background-color: $cgp_white;
		border: none;
	}
	&.active .sub:before {
		background-color: $cgp_white;
		border: none;
		// background-image: url("/img/icon_ok.png");
		background-position: center center;
		background-size: 20px;
		background-repeat: no-repeat;
	}
	.sub .txt {
		font-size: 14px;
		line-height: 22px;
		span {
		display: block;
		}
	}
	}
}
.paket-form {
	max-width: 400px;
	margin: 0 auto;
	input {
	background-color: transparent;
	border: 1px solid $cgp_gray_light;
	color: $cgp_gray_light;
	&::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, &:-moz-placeholder {
		color: $cgp_gray_light;
	}
	}
	.checker {
	width: 24px;
	height: 24px;
	border: 1px solid $cgp_gray_light;
	display: inline-block;
	margin-right: 5px;
	position: relative;
	.glyphicon-ok {
		display: none;
		font-size: 11px;
		padding-left: 2px;
		//color: #469d2c;
		color: darken($cgp_primary, 3%);
		position: absolute;
		top: 5px;
	}
	&.checked .glyphicon-ok {
		display: inline-block;
	}
	}
	a {
	color: inherit;
	text-decoration: underline;
	&:hover, &:active {
		color: inherit;
		text-decoration: underline;
	}
	}
}
&.thank-you {
	padding: 200px 0;
	p {
	padding-bottom: 30px;
	border-bottom: 1px solid #5d5d5d;
	}
}
.foot {
	margin-top: 25px;
	text-align: center;
	.btn {
	background-color: $cgp_primary;
	padding: 12px 25px;
	}
	span {
	display: block;
	padding-top: 20px;
	padding-bottom: 40px;
	color: $cgp_primary;
	}
}
}

@media screen and (max-width: 500px) {
.paketi-page .paketi-list {
	padding: 30px 30px;
}
}

/* FORM - 2nd page */

@media screen and (min-width: 544px) {
.paketi-page .paket-form {
	.m-noright {
	padding-right: 5px;
	}
	.m-noleft {
	padding-left: 5px;
	}
}
}

/* THANK YOU - 3nd page */

@media screen and (max-width: 700px) {
.paketi-page.thank-you {
	padding: 50px 0;
}
}

/* FOOT */

/**********************************
*******  GROUP FAMILY PAGE ******
*********************************/

.image-container.main.family .special-paketi {
position: absolute;
bottom: 20px;
width: 100%;
color: $cgp_white;
&:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 5px;
	top: 22.5px;
	display: block;
	background-color: $cgp_primary;
}
.button {
	width: 280px;
	margin: 0 auto;
	background-color: $cgp_primary;
	padding: 9px 60px;
	font-size: 13px;
	line-height: 16px;
	position: relative;
	cursor: pointer;
	&:before {
	content: "";
	position: absolute;
	width: 50px;
	height: 100%;
	left: 4px;
	top: 0;
	display: block;
	// background: url('/img/icon-letter.png') no-repeat center center;
	background-size: 29px;
	}
	&:after {
	content: ">";
	position: absolute;
	color: $cgp_primary;
	right: 10px;
	top: 15px;
	width: 20px;
	height: 20px;
	display: block;
	background-color: $cgp_white;
	padding: 3px;
	text-align: center;
	}

	//p {}

}
}

@media screen and (max-width: 500px) {
.image-container.main.family .special-paketi {
	position: relative;
	margin-top: 40px;
}
}

@media screen and (max-width: 480px) {
.image-container.main.family .special-paketi {
	margin-top: 22px;
}
}
