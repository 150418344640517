/* Reset */
@charset "UTF-8";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
#main .main-container section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  /*  font: inherit;
	font-size: 100%;
	vertical-align: baseline;*/
}

html {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}

q {
  &:before,
  &:after {
	content: "";
	content: none;
  }
}

blockquote {
  &:before,
  &:after {
	content: "";
	content: none;
  }
}

a img {
  border: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
#main .main-container section,
summary {
  display: block;
}

html,
body {
  height: 100%;
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  padding: 0;
  list-style: none;
}

input:focus,
textarea:focus {
  outline: none;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
  margin: 0;
}

/* Prepreci oznacitev teksta na klik */

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
}

/*******************************************************************************
* COMMON
*******************************************************************************/

//a {color: $cgp_white;}
a:hover {
  text-decoration: none;
}

body.page-home {
  color: $cgp_white;
  background-color: $body_background;
  font-weight: 400;
  letter-spacing: 0;
}

#container {
  position: relative;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 30px;
  min-height: 100%;
  overflow: hidden;
}

body.page-home #container {
  padding-top: 65px;
}

.main-container {
  padding-top: 30px;
}

#main {
  padding-bottom: 185px;
  position: relative;
}

.page-home #main {
  padding-bottom: 90px;
}

.more {
  height: 50px;
  background-color: $cgp_primary;
  border-radius: 2px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  color: $cgp_white;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  padding: 0 20px;
}

@media screen and (max-width: 1200px) {
  #main {
	padding-bottom: 160px;
  }
}

@media screen and (max-width: 992px) {
  #main {
	padding-bottom: 0;
	margin-bottom: 30px;
  }
}

/*******************************************************************************
* 1.0 - HEADER
*******************************************************************************/

.header-skoda {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

	// Black background on top of header (replacement of Anolis header)
	&:before {
		content: "";
		display: block;
		background-color: $body_background;
		position: absolute;
		left: 0;
		top: 0;
		height: 16px;
		width: 100%;
		pointer-events: none;

		@include translate(0, -100%);
	}

}

.header-main {
  z-index: 9;
  position: relative;
  height: 65px;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  .content {
	position: relative;
	height: 100%;
  }
  .logo {
	display: block;
	float: left;
	position: relative;
	top: 8px;
	margin-right: 45px;
  }
}

/* Skopirat za meni - zacetek */

.openmenu {
  display: none;
}

.menu-main {
  nav {
	float: left;
	li {
	  display: block;
	  float: left;
	  a {
		font-size: 14px;
		font-weight: 400;
		line-height: 61px;
		display: block;
		border-bottom: 4px solid transparent;
		color: $cgp_black;
		&:hover,
		&:focus {
		  text-decoration: none;
		}
		&.selected,
		&:hover {
		  border-bottom: 4px solid $cgp_primary;
		}
		~ b {
		  display: none;
		}
	  }
	  + li {
		margin-left: 45px;
	  }
	  &:hover .sub,
	  &.selected .sub {
		opacity: 1;
		visibility: visible;
	  }
	}
	.sub {
	  color: $cgp_black;
	  opacity: 0;
	  visibility: hidden;
	  padding: 30px 0;
	  position: fixed;
	  background-color: $cgp_white;
	  left: 0;
	  width: 100%;
	  border-top: 1px solid $cgp_gray_light;
	  &:before {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: -3px;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
		width: 100%;
		height: 2px;
	  }
	  span {
		font-size: 24px;
		font-weight: 400;
		line-height: 1em;
	  }
	  li {
		line-height: 100%;
		float: none;
		a {
		  line-height: 1em;
		  color: $cgp_primary;
		  padding: 15px 0;
		  border-bottom: 1px solid $cgp_gray_light;
		  font-size: 16px;
		  &:hover {
			border-bottom: 1px solid $cgp_primary;
		  }
		}
		&:first-child a {
		  padding-top: 0;
		}
		+ li {
		  margin: 0;
		}
	  }
	}
  }
  .right-side {
	float: right;
	nav li {
	  &.level1 {
		display: block;
		float: left;
		border-left: 1px solid $cgp_gray_light;

		// Mobile
		@media screen and (max-width: 992px) {
			border-left: none;
		}

	  }
	  padding: 0 30px;
	  &:last-of-type {
		padding-right: 0;
	  }
	  a {
		color: $cgp_primary;
		font-weight: 700;
		padding-left: 30px;
		border-bottom: none;
		line-height: 65px;
		position: relative;
		&.selected,
		&:hover {
		  border-bottom: none;
		}
	  }
	  &.skoda a {
		background: url("/media/icons/skoda.svg") no-repeat scroll left
		  (center / 22px);
	  }
	  &.test-drive a {
		background: url("/media/icons/testne-voznje.svg") no-repeat scroll left
		  (center / 25px);
	  }
	  &.map a {
		background: url("/media/icons/map.svg") no-repeat scroll left (center / 23px);
	  }
	  &.wishlist a:before {
		left: 0;
		line-height: inherit;
		position: absolute;
	  }
	}
  }
}

/* poseben podmenu za zaklad */
@media screen and (min-width: 992px) {
  .menu-main nav .sub li.zaklad {
	position: absolute;
	top: -15px;
	left: 130%;
	width: 100%;
  }
}

/* Wishlist dropdown */

body.desktop-view .menu-main .right-side nav li.wishlist .sub {
  padding: 0;
  width: 100%;
  max-width: 100%;
  height: 0;
  .container {
	padding: 0;
	width: 100%;
	max-width: 100%;
	height: 0;
	.row {
	  margin: 0;
	}
  }
  .menu-level2 {
	width: 100%;
  }
  &:before,
  .menu-level2.first {
	display: none;
  }
}

.menu-main .right-side nav li {
  &.wishlist {
	a .dropdown-menu {
	  border-radius: 0;
	  box-shadow: none;
	  padding: 0;
	  right: -15px;
	  top: 63px;
	}
	&:hover .dropdown-menu {
	  display: block;
	  margin: 0;
	  top: auto;
	  border: none;
	}
	.dropdown-menu {
	  a {
		padding-left: 0;
	  }
	  li {
		padding: 15px;
	  }
	  .wish-car {
		border: 1px solid #ececec;
		display: inline-block;
		vertical-align: top;

		//+ .wish-car {}

		.wish-top {
		  border-bottom: 1px solid #ececec;
		  img {
			width: 140px;
		  }
		}
		.wish-bottom {
		  padding: 10px;
		  p {
			font-weight: bold;
			line-height: 20px;
			margin: 0;
			padding: 0;
			&.green {
			  color: $cgp_primary;
			}
		  }
		}
	  }
	  .wish-buttons {
		line-height: 20px;
		margin-top: 10px;
		.btn {
		  float: left;
		  margin-bottom: 3px;
		  margin-right: 5px;
		}
	  }
	}
  }
  + li {
	margin: 0;
  }
}

html.ie .menu-main .right-side nav li.test-drive a {
  background-size: 50px auto;
  background-size: 50px auto;
}

@media screen and (max-width: 1450px) {
  .header-main .logo {
	margin-right: 5px;
  }
  .menu-main {
	.right-side nav li {
	  padding: 0 5px;
	}
	nav li + li {
	  margin-left: 5px;
	}
  }
}
@media screen and (max-width: 1605px) {
  .header-main .logo {
	margin-right: 15px;
  }
  .menu-main {
	.right-side nav li {
	  padding: 0 15px;
	}
	nav li + li {
	  margin-left: 15px;
	}
  }
}

@media (min-width: 992px) and (max-width: 1380px) {
  .menu-main .right-side nav li {
	a {
	  height: 66px;
	  padding-left: 0;
	  text-indent: -999em;
	  width: 65px;
	}
	&.skoda a {
	  background: rgba(0, 0, 0, 0) url("/media/icons/skoda.png") no-repeat
		scroll center (center / 33px);
	}
	&.test-drive a {
	  background: rgba(0, 0, 0, 0) url("/media/icons/testne-voznje.png")
		no-repeat scroll center (center / 35px) auto;
	}
	/*&.map a {
	  background: rgba(0, 0, 0, 0) url("../img/map.svg") no-repeat scroll center
		(center / 35px) auto;
	}*/
	&.wishlist a:before {
	  font-size: 35px;
	  left: 14px;
	  top: 4px;
	  text-indent: 0;
	}
  }
}

@media screen and (max-width: 1200px) {
  .menu-main .right-side nav li {
	padding: 0 10px;
  }
  .desktop-view .menu-main .right-side nav li a {
	/*background-image: none !important;*/
	padding-left: 0;
	font-size: 13px;
  }
  /* .desktop-view .menu-main .right-side nav li.wishlist a:before {display: none;} */
}

/* Skopirat za meni - konec */
/*******************************************************************************
* 2.0 - MODULE : Slideshow
*******************************************************************************/

.module-slideshow {
  position: relative;
  height: auto;
  overflow: hidden;
  .slick-slider {
	margin: 0;
  }
  .slick-item {
	height: auto;
	background: url() no-repeat scroll center top;
	background-size: cover;
	position: relative;
	.image {
	  position: relative;
	  .img-container {
		/* width: 50%; */
		/* position: absolute; */
		right: 0;
	  }
	}
	.text {
	  position: absolute;
	  left: 0;
	  top: 0;
	  padding: 50px 45px;
	  h2 {
		margin-bottom: 0;
	  }
	  span {
		font-weight: 700;
		font-size: 25px;
		margin-bottom: 30px;
		display: block;
		em {
		  font-weight: 400;
		  font-style: normal;
		}
	  }
	}
  }
  .slide-arrow {
	background-color: $cgp_black;
	cursor: pointer;
	display: block !important;
	height: 48px;
	position: absolute;
	top: 50%;
	width: 25px;
	z-index: 9;
	&:before {
	  background: none no-repeat scroll center center;
	  content: "";
	  display: block;
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 100%;
	  height: 100%;
	}
	&.slide-prev {
	  left: 0;
	  &:before {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQBAMAAAA2ZkhwAAAAElBMVEUAAAD///////////////////8+Uq06AAAABnRSTlMAAZHID3FpHDJ9AAAAIUlEQVQI12MAAkYBECkUAGIqO4CYJjiYEBIiQoATADEZAOgMBOPFiM9OAAAAAElFTkSuQmCC);
	  }
	}
	&.slide-next {
	  right: 0;
	  &:before {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQBAMAAAA2ZkhwAAAAD1BMVEUAAAD///////////////+PQt5oAAAABXRSTlMAkcgPcfixGu0AAAAeSURBVAjXY4AARzBpJAAimRVxcyAkRAQ7E2IaHAAAjcIC5/fI3/4AAAAASUVORK5CYII=);
	  }
	}
  }
}

/*.module-slideshow .slick-dots {
	position: absolute;
	bottom: 5px;
	left: 0;
	width: 100%;
	height: 20px;
	display: none !important;
}
.module-slideshow .slick-dots li {
	vertical-align: top;
}
.module-slideshow .slick-dots li button {
	background-color: $cgp_white;
	border-radius: 100%;
}
.module-slideshow .slick-dots li button:before {
	display: none;
}
.module-slideshow .slick-dots li.slick-active button {
	background-color: $cgp_gray_light;
}*/

/*******************************************************************************
* 3.0 - #main .main-container sectionS
*******************************************************************************/

.main-container .row + .row {
  margin-top: 30px;
}

#main .main-container section {
  position: relative;
  overflow: hidden;
  margin-top: 0;
  .text {
	color: $cgp_white;
	h2,
	h3 {
	  color: inherit;
	  font-size: 60px;
	  font-weight: 700;
	  line-height: 1em;
	}
	h2 {
	  font-size: 60px;
	  margin-bottom: 0.35em;
	}
	h3 {
	  font-size: 30px;
	  margin-bottom: 0.2em;
	  &.text-family {
		color: $cgp_black;
	  }
	}
	p {
	  color: inherit;
	  font-size: 14px;
	  font-weight: 700;
	  line-height: 1.4em;
	}
	span {
	  color: inherit;
	  font-size: 16px;
	  font-weight: 700;
	  line-height: 1.625em;
	}
  }
  &.txt-black .text {
	color: $cgp_black;
  }
  &.txt-shadow .text {
	h1,
	h2,
	p {
	  text-shadow: 1px 1px 1px $cgp_black;
	}
  }
  &.txt-bottom .content {
	top: auto;
	bottom: 0;
  }
  &.txt-right .text > * {
	text-align: right;
  }
  .image img {
	width: 100%;
	max-width: 100%;
  }
  .content {
	position: absolute;
	left: 0;
	top: 0;
	padding: 25px 30px;
	width: 100%;
  }
  &.obletnica .content {
	top: inherit;
	bottom: 0;
  }
  &.procentplus .content .text {
	color: $cgp_white;
  }
  &.intro p {
	font-weight: 700;
	color: $cgp_white;
	font-size: 116px;
	line-height: 1em;
	text-transform: uppercase;
	em {
	  color: $cgp_secondary;
	  font-size: inherit;
	  font-style: normal;
	}
	b {
	  font-weight: 700;
	  left: 0em;
	  margin-right: 0.1em;
	  position: relative;
	}
	i {
	  position: relative;
	  margin-right: 0.05em;
	  margin-left: 0.1em;
	  font-style: normal;
	}
  }
  &.family .text p {
	color: inherit;
	font-size: 18px;
	font-weight: 400;
	line-height: 1.55em;
	margin-bottom: 1.1em;
  }
}

/* ZAKLAD */

/*******************************************************************************
* 3.1 - #main .main-container section: Intro
*******************************************************************************/

/*******************************************************************************
* 3.2 - #main .main-container section: Family matters
*******************************************************************************/

/*******************************************************************************
* 3.10 - #main .main-container section: Responsive
*******************************************************************************/
@media screen and (max-width: 1450px) {
  #main .main-container section.intro p {
	font-size: 8vw;
  }
}

@media screen and (min-width: 992px) and (max-width: 1450px) {
  #main .main-container section {
	.text {
	  h2 {
		font-size: 4.15vw;
	  }
	  h3 {
		font-size: 2vw;
	  }
	  span {
		font-size: 1.1vw;
	  }
	  p {
		font-size: 1vw;
	  }
	}
	&.family .text p {
	  font-size: 1.25vw;
	}
	.content {
	  padding: 1.75vw 2vw;
	}
  }
  .module-slideshow .slick-item .text span {
	font-size: 1.7vw;
  }
}

@media screen and (max-width: 992px) {
  .intro,
  .bp-md-marbot30,
  .module-slideshow {
	margin-bottom: 30px;
  }
  #main .main-container section.intro p {
	font-size: 116px;
	margin-bottom: 0.5em;
  }
}

@media screen and (max-width: 767px) {
  #main .main-container section {
	&.bp-sm-marbot30 {
	  margin-bottom: 30px;
	}
	&.intro p {
	  font-size: 90px;
	}
	.text {
	  h2 {
		font-size: 46px;
	  }
	  h3 {
		font-size: 38px;
	  }
	  span {
		font-size: 18px;
	  }
	  p {
		font-size: 14px;
	  }
	}
	&.family .text p {
	  font-size: 16px;
	}
	.content {
	  padding: 25px 30px;
	}
  }
  .module-slideshow .slick-item .text span {
	font-size: 18px;
  }
}

@media screen and (max-width: 544px) {
  #main .main-container section {
	&.intro p {
	  font-size: 16vw;
	}
	.text {
	  h2,
	  h3 {
		font-size: 8vw;
	  }
	  span {
		font-size: 3.4vw;
	  }
	  p {
		font-size: 1vw;
	  }
	}
	&.family .text p {
	  font-size: 2.5vw;
	}
	.content {
	  padding: 4vw 4vw;
	}
  }
  .module-slideshow .slick-item .text {
	span {
	  font-size: 3.1vw;
	}
	padding: 8.5vw 8.5vw;
  }
  #main .main-container section.obletnica .text {
	h3 br {
	  display: none;
	}
	p {
	  font-size: 3vw;
	  max-width: 75%;
	  float: right;
	  line-height: 1.1em;
	  br {
		display: none;
	  }
	}
  }
  .more {
	height: 7vw;
	line-height: 7vw;
	padding: 0 5vw;
	font-size: 3vw;
  }
}

/*******************************************************************************
* 4.0 - HEADINGS
*******************************************************************************/

.container-960 {
  width: 940px;
  margin: 0 auto;
}

.image-container {
  .container-960 {
	height: 100%;
	position: relative;
  }
  .heading {
	color: $cgp_white;
	position: relative;
	text-align: left;
	h1 {
	  color: inherit;
	  font-size: 72px;
	  font-weight: 700;
	  line-height: 1em;
	  margin-bottom: 0.33em;
	  text-align: inherit;
	}
	p {
	  color: inherit;
	  font-size: 24px;
	  font-weight: 400;
	  line-height: 1.25em;
	  overflow: hidden;
	  text-align: inherit;
	  img {
		float: right;
		margin-top: 20px;
	  }
	}
  }
  &.polovicka .heading p img,
  &.procentplus .heading p img,
  &.procent .heading p img,
  &.sbon .heading p img,
  &.scoutline .heading p img,
  &.montecarlo .heading p img,
  &.easy .heading p img,
  &.soundcolour .heading p img,
  &.family .heading p img {
	float: none;
	display: inline-block;
  }
  .heading.centered {
	text-align: center;
	top: 50%;
	transform: translate(0px, -50%);
	-webkit-transform: translate(0px, -50%);
	-moz-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	-o-transform: translate(0px, -50%);
  }
  &.hudobro .heading,
  &.easy .heading,
  &.soundcolour .heading {
	color: $cgp_black;
  }
  &.procent .heading {
	color: $cgp_black;
	padding-top: 30px;
	text-align: left;
  }
  &.fabia .heading,
  &.sbon .heading,
  &.scoutline .heading,
  &.montecarlo .heading,
  &.family .heading,
  &.soundcolour .heading,
  &.easy .heading {
	padding-top: 30px;
	text-align: left;
  }
  &.hudobro .heading {
	text-align: left;
	/* color: $cgp_white; */
	color: $cgp_black;
	padding-top: 60px;
  }
  &.procent .heading {
	padding-top: 60px;
  }
  &.family .heading {
	h1,
	p {
	  text-shadow: 1px 1px 1px $cgp_black;
	}
  }
}

/* easy izjema */

/*Car carousel */

#slider-swipe {
  background-color: #ececec;
}

/* Responsive */

/* 992px */
@media screen and (max-width: 992px) {
  .container-960 {
	width: 100%;
	padding: 0 15px;
  }
  .image-container {
	//.container-960 {}

	.heading {
	  h1 {
		font-size: 56px;
	  }
	  p {
		font-size: 20px;
		img {
		  max-width: 220px;
		}
	  }
	}
  }
}

/* 767px */
@media screen and (max-width: 767px) {
  .image-container {
	.heading {
	  h1 {
		font-size: 48px;
	  }
	  p {
		font-size: 16px;
	  }
	}
	&.hudobro .heading,
	&.procent .heading {
	  padding-top: 30px;
	}
	.heading p img {
	  max-width: 160px;
	}
  }
}

/* 480px */
@media screen and (max-width: 480px) {
  .image-container .heading {
	h1 {
	  font-size: 38px;
	}
	p {
	  font-size: 14px;
	  img {
		max-width: 120px;
	  }
	}
  }
}

/* 375px */
@media screen and (max-width: 375px) {
  .image-container .heading {
	h1 {
	  font-size: 30px;
	}
	p {
	  font-size: 12px;
	}
  }
}

/*******************************************************************************
* 9.0 - FOOTER
*******************************************************************************/

.footer-main {
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
  position: absolute;
  width: 100%;
}

.page-home .footer-main {
  padding-bottom: 45px;
  z-index: 999;
}

.footer-main .links {
  text-align: left;
  float: left;
  a {
	color: $cgp_white;
	font-size: 12px;
	font-weight: 400;
	line-height: 2em;
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding-right: 20px;
  }
}

body:not(.home-page) .footer-main .links a {
  color: $cgp_gray;
}

.footer-main .links {
  a {
	&:after {
	  content: "";
	  display: block;
	  right: 9px;
	  height: 12px;
	  top: 50%;
	  position: absolute;
	  margin-top: -6px;
	  border-left: 1px solid #777576;
	  border-right: 1px solid #a3a2a2;
	}
	&:last-of-type {
	  padding-right: 0;
	  &:after {
		display: none;
	  }
	}
  }
  &.align-right {
	text-align: right;
	float: right;
  }
}

@media screen and (max-width: 1200px) {
  .footer-main .links {
	float: none !important;
	&.align-right {
	  text-align: left;
	}
  }
}

@media screen and (max-width: 992px) {
  .footer-main {
	position: relative;
	left: auto;
	bottom: auto;
  }
}

@media screen and (max-width: 767px) {
  .footer-main {
	padding-bottom: 15px;
	.links a {
	  display: block;
	  &:after {
		display: none;
	  }
	}
  }
}

/*******************************************************************************
* 10 - RESPONSIVE
*******************************************************************************/

html.noscroll {
  position: relative;
  overflow: hidden;
  body {
	position: relative;
	overflow: hidden;
  }
}

/* Skopirat za mobili meni - zacetek */

.menu-title {
  display: none;
}

.openmenu {
  cursor: pointer;
  display: none;
  float: right;
  height: 65px;
  padding: 5px;
  position: relative;
  z-index: 9;
  border-left: 1px solid $cgp_gray_light;
  .line {
	background-color: currentcolor;
	color: $cgp_primary;
	height: 3px;
	left: 5px;
	margin-top: -1px;
	position: absolute;
	top: 50%;
	width: 30px;
	&:before,
	&:after {
	  background-color: $cgp_primary;
	  content: "";
	  cursor: pointer;
	  height: 3px;
	  position: absolute;
	  right: 0;
	  width: 100%;
	}
	&:before {
	  top: -10px;
	  transition: top 200ms ease-out 0s;
	}
	&:after {
	  bottom: -10px;
	  transition: bottom 200ms ease-out 0s;
	}
  }
  &.open .line {
	color: #5d6f7a;
  }
}

body.mobile-view {
  header {
	&.header-main {
	  .content {
		padding: 0;
		width: 100%;
		position: unset;
	  }
	  .openmenu {
		display: block;
		padding-right: 65px;
		z-index: 9;
		position: relative;
		.line {
		  color: $cgp_primary;
		  left: auto;
		  right: 15px;
		  transition: all 50ms linear 0s;
		  -webkit-transition: all 50ms linear 0s;
		  &:before,
		  &:after {
			transition: all 200ms linear 0s;
			-webkit-transition: all 200ms linear 0s;
		  }
		}
		&.open .line {
		  background-color: transparent;
		  transition: all 50ms linear 0s;
		  -webkit-transition: all 50ms linear 0s;
		  &:before {
			top: 0;
			transition: all 200ms linear 0s;
			-webkit-transition: all 200ms linear 0s;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
		  }
		  &:after {
			bottom: 0;
			transition: all 200ms linear 0s;
			-webkit-transition: all 200ms linear 0s;
			transform: rotate(-45deg);
			-webkit-transform: rotate(-45deg);
		  }
		}
	  }
	}
	.right-side {
	  position: relative;
	  float: none;
	  background-color: #f9f9f8;
	}
  }
  div.menu-main {
	background-color: $cgp_white;
	left: 0;
	opacity: 0;
	position: fixed;
	top: 81px;
	width: 100%;
	@include translate(100%, 0);
	@include transition(transform 500ms ease-out);

	&:before {
	  content: "";
	  display: block;
	  position: absolute;
	  left: 0;
	  top: -3px;
	  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
	  width: 100%;
	  height: 3px;
	  z-index: 9;
	}
	&.open {
		@include translate(0, 0);
		@include transition(transform 500ms ease-out);
		opacity: 1;
	}
	&.swipe-left-100 {
	  left: -100%;
	}
	&.swipe-left-200 {
	  left: -200%;
	}
	nav {
	  display: block;
	  float: none;
	  ul {
		position: relative;
		z-index: 1;
		li {
		  float: none;
		  padding-left: 30px;
		  padding-right: 0;
		  border-bottom: 1px solid $cgp_gray_light;
		  a {
			font-size: 16px;
			height: 60px;
			line-height: 60px;
			width: 100%;
			display: block;
			border: medium none;
		  }
		}
	  }
	}
	.right-side nav ul li a {
	  padding-left: 30px;
	}
	nav ul li.wishlist .dropdown-menu {
	  border: medium none;
	  display: block;
	  float: none;
	  margin: 0;
	  padding: 0;
	  li {
		border: medium none;
		padding: 15px 15px 0;
	  }
	  .wish-car {
		margin: 0 2% 15px 0;
		width: 18%;

		//+ .wish-car {}

		.wish-top img {
		  margin: 0 auto;
		}

		//.wish-bottom {}

		.wish-buttons {
		  overflow: hidden;
		}
	  }
	}
  }
  header.header-main .container {
	padding: 0;
	width: 100%;
	max-width: 100%;
	.row {
	  height: 100%;
	  margin: 0;
	}
  }
  div.menu-main-levels .menu-level2 {
	padding: 0;
	&.first {
	  display: none;
	}
  }
  .menu-main {
	nav > ul {
	  position: relative;
	  z-index: 1;
	  margin: 0;
	  display: block;
	  background-color: $cgp_white;
	}
	ul li {
	  display: block;
	  float: none;
	  height: auto;
	  margin: 0;
	  padding: 0;
	  position: relative;
	  width: 100%;
	  a ~ b {
		display: block;
		height: 100%;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 3;
		left: 0;
		&:before {
		  background: transparent url("/media/icons/menu-arrow.svg") no-repeat scroll
			center (center / 10px) auto;
		  content: "";
		  display: block;
		  height: 100%;
		  position: absolute;
		  right: 0;
		  width: 90px;
		  transform: rotate(0deg);
		  -webkit-transform: rotate(0deg);
		  -moz-transform: rotate(0deg);
		  -ms-transform: rotate(0deg);
		  -o-transform: rotate(0deg);
		  transition: transform 250ms linear 0s;
		  -moz-transition: transform 250ms linear 0s;
		  -webkit-transition: transform 250ms linear 0s;
		  -o-transition: transform 250ms linear 0s;
		}
	  }
	}
  }
  .menu-main-levels {
	left: 100%;
	opacity: 1;
	overflow: hidden;
	padding: 0;
	top: 81px;
	visibility: visible;
	width: 100%;
	z-index: 2;
	border-top: none;
	position: fixed;
	background-color: $cgp_white;
	transition: left 500ms ease-in 0s;
  }
  .menu-main nav > ul li.open .menu-main-levels {
	left: 0;
	transition: left 500ms ease-in 0s;
	li a {
	  padding: 0;
	  &:hover {
		border-bottom: none;
	  }
	}
  }
  .menu-main-levels .menu-title {
	cursor: pointer;
	display: block;
	height: 61px;
	line-height: 60px;
	background-color: #eee;
	border-bottom: 1px solid $cgp_gray_light;
	h6 {
	  font-size: 24px;
	  line-height: inherit;
	  text-align: center;
	  position: relative;
	  &:before {
		background: $cgp_gray_light url("/media/icons/menu-arrow-back.svg") no-repeat scroll center
		  (center / 16px) auto;
		content: "";
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 75px;
	  }
	}
  }
}

/* Openmenu */

/* Header right side */

/* Menu links */

/* Wishlist */

/* Responsive */
@media screen and (max-width: 767px) {
  body.mobile-view div.menu-main nav ul li.wishlist .dropdown-menu .wish-car {
	width: 23%;
  }
}

@media screen and (max-width: 544px) {
  body.mobile-view div.menu-main nav ul li.wishlist .dropdown-menu .wish-car {
	width: 31%;
  }
}

@media screen and (max-width: 480px) {
  body.mobile-view div.menu-main nav ul li.wishlist .dropdown-menu .wish-car {
	width: 46%;
	.wish-buttons {
	  margin: 0;
	  padding: 0 0 10px;
	  .btn {
		margin: 0 0 15px;
	  }
	  a {
		height: auto;
		line-height: 100%;
	  }
	}
  }
}

@media screen and (max-width: 375px) {
  body.mobile-view div.menu-main nav ul li.wishlist .dropdown-menu .wish-car {
	width: 100%;
  }
}

/*******************************************************************************
* 10.1 - RESPONSIVE: Main menu
*******************************************************************************/

/*body.mobile-view .menu-main ul li ul {
	left: auto;
	opacity: 1;
	position: relative;
	top: auto;
	visibility: visible;
	width: 100%;
}*/
/*
body.mobile-view .menu-main-levels .content {
	padding: 0;
	border: none;
	overflow: visible;
	min-height: 100%;
}

body.mobile-view .menu-main-levels.overflow {
	overflow: visible;
}
*/
/*******************************************************************************
* 10.1.1 - HEADER: Mobile - Level 2
*******************************************************************************/

/* Menu title - gumb za nazaj v prvi nivo */

@media screen and (max-width: 544px) {
  body.mobile-view .menu-main-levels .menu-title h6 {
	font-size: 20px;
  }
}

@media screen and (max-width: 375px) {
  body.mobile-view .menu-main ul li a {
	font-size: 16px;
  }
}

/* Skopirat za mobilni meni - konec */

.slick-slideshow {
  height: 0;
  padding-bottom: 62%;
}

.slick-slider .slick-list {
  overflow: visible;
}

#main .main-container section .slick-slider .slick-track .image img {
  position: absolute;
  right: 0;
  width: 76%;
  padding-top: 17%;
}

@media (min-width: 992px) and (max-width: 1250px) {
  #main .main-container section .slick-slider .slick-track .image img {
	width: 64%;
	padding-top: 20%;
  }
}

@media (min-width: 1250px) and (max-width: 1400px) {
  #main .main-container section .slick-slider .slick-track .image img {
	width: 70%;
	padding-top: 18%;
  }
}

/*******************************************************************************
* 11 - Misc
*******************************************************************************/

.specs-selector {
  position: relative;
  &:after {
	content: "";
	position: absolute;
	left: 50%;
	bottom: -6px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 13px 0 13px;
	border-color: #ececec transparent transparent transparent;
	margin-left: -13px;
  }

  .container-960 {
	@media (min-width: 1200px) {
		width: 1140px;
	}
  }
}

/* Tabs collapse */

#main .tab-header {
  counter-reset: section;
  font-size: 0;
  position: relative;
  ul {
	position: relative;
  }
  li {
	font-size: 16px;
  }
  .tab-opener {
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	&:after {
	  background: transparent url("/media/icons/tab-opener.png") no-repeat scroll
		center center;
	  content: "";
	  cursor: pointer;
	  display: block;
	  height: 30px;
	  position: absolute;
	  right: 0;
	  top: 17px;
	  width: 30px;
	  z-index: 9;
	  pointer-events: none;
	}
  }
  &.open {
	.tab-opener:after {
	  transform: rotate(90deg);
	}
	li {
	  display: block !important;
	}
  }
}

/*#main .tab-header.collapse {display: block;}
#main .tab-header.collapse li {display: none;}

#main .tab-header.collapse li.active {
	display: block;
	margin: 0;
	padding-right: 35px;
}

#main .tab-header.collapse li + li {margin: 0;}*/

/* 768px */
@media screen and (max-width: 768px) {
  #main .tab-header {
	.tab-opener {
	  display: block;
	}
	display: block;
	li {
	  display: none;
	  &.active {
		display: block;
		margin: 0;
		padding-right: 35px;
	  }
	  + li {
		margin: 0;
	  }
	}
	ul {
	  margin: 0 -15px;
	  margin-bottom: 1px;
	}
  }
}

/*******************************************************************************
* 12 - MAGAZINE POPUP
*******************************************************************************/

.mag-modal {
  color: $cgp_black;
  .close {
	margin-top: -10px;
	font-weight: 400;
  }
  .modal-body hr {
	border-color: $cgp_gray_light;
  }
  h3,
  h4 {
	font-weight: 700;
  }
  .agree {
	font-size: 16px;
	font-weight: 400;
  }
}

.divider {
  background-color: $cgp_gray_light;
  border: 0;
  height: 1px;
  margin: 22px 0;
}
