html body {
	overflow-x: hidden;

	*:focus {
		outline: none;
		text-decoration: none;
	}
}

.loading-gif {
	display: block;
	margin: 0 auto;
	height: 40px;
}

.cookie-info {
	background: none #eee;
	text-align: center;
	position: absolute;
	bottom: 0;
	padding-bottom: 4px;
	padding-top: 4px;
	width: 100%;
	z-index: 9999;
	font-weight: 400;
	font-size: 12px;
	color: #555;

	.accept {
		color: $cgp_primary;
		padding-left: 16px;
		text-decoration: none;
		outline: none;

		&::before {
			content: ">";
			color: $cgp_white;
			display: inline-block;

			//background-color: #53a71a;
			background-color: $cgp_primary;
			width: 14px;
			height: 16px;
			font-size: 14px;
			line-height: 17px;
			margin: 0 4px;
		}
	}
}

#top-drop-down.nulled {
	background-color: $cgp_white;
	height: 16px;
	left: 0;
	margin: 0;
	max-height: 16px;
	overflow: hidden;
	position: fixed;
	width: 100%;
}

.radio-wrapper {
	.radio-position {
		position: absolute;
		display: inline-block;
		width: 18px;
		height: 18px;
		top: 1px;
		border-radius: 50%;
		background: $cgp_white;
		transition: all 500ms ease-out;

		&.left {
			left: 1px;
		}

		&.middle {
			left: 13px;
		}

		&.right {
			left: 26px;
		}
	}

	label {
		display: inline-block;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		position: absolute;

		&.left {
			left: 1px;
		}

		&.middle {
			left: 33%;
		}

		&.right {
			left: 66%;
		}
	}
}

.pointer {
	cursor: pointer !important;
}

.bordered .selected-most-viewed-img img {
	width: 100%;
	position: relative;
	top: 50%;
	transform: translate(0, -50%);
}

.models-list {
	margin-bottom: 30px;
}

.model-content .tworowname {
	min-height: 3em;

	&.smaller {
		min-height: 2em;
	}
}

.most-viewed-bottom {
	cursor: pointer;
}

.most-viewed-top {
	cursor: pointer;

	.glyphicon {
		top: 5px;
	}
}

.most-viewed-assets {
	overflow: auto;
}

article {
	.animate {
		overflow: hidden;
	}

	.animate-assets {
		max-height: 378px;
		overflow: auto;
		z-index: 50;
		font-size: 14px;
	}
}

.interior-list {
	max-height: 400px;
	overflow: auto;

	.model-content .most-viewed-highlight > strong {
		width: 45%;
	}
}

.most-viewed .model-content .most-viewed-highlight > strong {
	width: 45%;
}

.interior-list .model-content .most-viewed-highlight > mark,
.most-viewed .model-content .most-viewed-highlight > mark {
	width: 55%;
}

.form-control.invalid {
	border-color: rgb(239, 169, 169);
}

footer .info {
	color: $cgp_gray;
	font-size: 11px;
	padding: 15px 0;
	text-align: justify;

	&.first {
		margin: 0;
	}
}

body #toast-container > .toast-success {
	background-image: none !important;
}

#toast-container > div {
	padding-left: 15px !important;
}

/* nav ul li { cursor:pointer; } */

#top-pick + div {
	margin: 0;

	> div {
		padding: 0;
	}
}

.nonemargin {
	margin: 0 !important;
}

.print-margin {
	padding-left: 20px;
}

.print-list {
	margin-right: 10px;
	margin-top: 10px;
	float: right;
}

.model-content .specs.smaller {
	min-height: 3.2em;
}

/* 992px */
@media screen and (max-width: 992px) {
	.model-content {
		.specs {
			min-height: 6em;
		}

		.tworowname {
			min-height: 4em;

			&.smaller {
				min-height: 3em;
			}
		}
	}
}

/* 767px */
@media screen and (max-width: 767px) {
	.model-content {
		.specs {
			min-height: 3em;

			&.smaller {
				min-height: 2em;
			}
		}

		.tworowname {
			min-height: 3em;
		}
	}

	/* Most viewed */
	.interior-list {
		max-height: 100%;
	}

	.bordered .selected-most-viewed-img {
		height: auto;
		margin: 0;

		img {
			top: auto;
			padding: 15px 0;
			transform: translate(0, 0);
		}
	}
}

/* 544px */
@media screen and (max-width: 544px) {
	/* Najbolj gledani */
	.model-content {
		.tworowname.smaller,
		.specs.smaller {
			min-height: 100%;
		}
	}
}

/* 450px */
@media screen and (max-width: 450px) {
	.model-content {
		.specs,
		.tworowname {
			min-height: 100%;
		}
	}

	/* Most viewed */
	.interior-list .model-content {
		.most-viewed-highlight > {
			strong,
			mark {
				width: 100%;
				float: none;
				padding: 0;
				margin-bottom: 30px;
			}
		}

		ul {
			margin-bottom: 30px;
		}
	}
}

/* DETAIL MAIN PIC */

.carousell-inner .slick-track {
	background-color: #ececec;
}

.item.slick-slide {
	min-height: 320px;

	&:focus {
		outline: 0;
	}
}

/* .item .carousel-caption {
	position:relative;
} */

/*
.item .bg-car {
	position: absolute;
	max-height: 370px;
	padding-top: 20px;
	z-index: 9;
	bottom: 90px;
	width: 50%;
	height: auto;
}
@media (min-width: 360px) {
	.item .bg-car {
		bottom: 70px;
	}
}
@media (min-width: 401px) {
	.item .bg-car {
		bottom: 50px;
	}
}
@media (min-width: 515px) {
	.item .bg-car {
		bottom: 25px;
	}
}
@media (min-width: 767px) {
	.item .bg-car {
		top:0;
		margin-left: 2%;
	}
}
@media (min-width: 1060px) {
	.item .bg-car {
		margin-left: 5%;
		height: 100%;
		width: auto;
	}
}
@media (min-width: 1300px) {
	.item .bg-car {
		margin-left: 15%;
	}
} */

/* .carousel #carousel-counter {
	left: 90%;
} */

/* MAIN PIC */

.image-container.main {
	background: url("/media/uploads/main-visual3.jpg") no-repeat scroll center bottom
		transparent;
	background-size: cover;

	/* &.hudobro {
		background-image: url("/img/zaklad-main_full.jpg");
	}
	&.fabia {
		background-image: url("/img/main-visual-skoda-zaklad.jpg");
	}
	&.polovicka {
		background-image: url("/img/polovicka.jpg");
	}
	&.family {
		background-image: url("/img/hudobro-si-podstran-visual_family-modeli.jpeg");
	}
	&.montecarlo {
		background-image: url("/img/skoda-2016-hudobro-monte-carlo.jpg");
	}
	&.sbon {
		background-image: url("/img/banners/skoda-bon.jpg");
	}
	&.scoutline {
		background-image: url("/img/banners/scout-line.jpg");
	}
	&.procent {
		background-image: url("/img/banners/financiranje_1_procent_big.jpg");
	}
	&.procentplus {
		background-image: url("/img/banners/financiranje-1procent-plus_big.jpg");
	}
	&.easy {
		background-image: url("/img/banners/easy_podstran.jpg");
	}
	&.soundcolour {
		background-image: url("/img/skoda-2016-hudobro-fabia-sound-colour.jpg");
	}
	&.starozanovo {
		background-image: url("/img/banners/starozanovo-full-main.jpg");
	} */
	height: 690px;
}

@media (max-width: 992px) {
	.image-container.main {
		height: 500px;
	}
}

@media (max-width: 767px) {
	.image-container.main {
		height: 400px;
	}
}

@media (max-width: 480px) {
	.image-container.main {
		height: 280px;

		&.family {
			height: 330px;
		}
	}
}

@media (max-width: 375px) {
	.image-container.main {
		height: 220px;
	}
}

/* @media (min-width: 1760px) {
	.image-container.main {
		height: 700px;
	}
} */

/* .main.hudobro .carousel-caption.main{
	margin-left: 20%;
	width: 65%;
} */

/* @media (min-width: 380px) {
	.main.hudobro .carousel-caption.main {
		margin-left: 25%;
		width: 50%;
	}
}
@media (min-width: 767px) {
	.main.hudobro .carousel-caption.main {
		margin-left: 33%;
		margin-top: 2%;
		width: 40%;
	}
} */

/* .main.procent .carousel-caption.main {
	margin-left: 0%;
	width: 65%;
} */

.main.procent .carousel-caption.main .procent {
	color: $cgp_black;
}

/* .main.procent .carousel-caption.main .procent h1 {
	font-family: 'Skoda ExtraBold';
	font-size: 30px;
} */

.carousel-caption.main .obletnica {
	h1 {
		font-family: "Skoda ExtraBold";
		font-size: 30px;
	}

	p {
		font-size: 12px;
	}

	img {
		width: 100px;
		margin-top: 0;
		max-width: 350px;
		float: right;
	}
}

@media (min-width: 767px) {
	.carousel-caption.main .obletnica h1 {
		font-size: 5.3vw;
	}
}

@media (min-width: 2000px) {
	.carousel-caption.main .obletnica h1 {
		font-size: 120px;
	}
}

@media (min-width: 767px) {
	.carousel-caption.main .obletnica p {
		font-size: 2vw;
	}
}

@media (min-width: 2000px) {
	.carousel-caption.main .obletnica p {
		font-size: 39px;
	}
}

@media (min-width: 767px) {
	.carousel-caption.main .obletnica img {
		width: 50%;
		margin-top: 20px;
	}
}

#top-button {
	ul .wish-car {
		display: list-item;
	}

	.wish-buttons .btn {
		float: left;
		margin-right: 5px;
		margin-bottom: 3px;
	}
}

@media (min-width: 800px) {
	#top-button ul .wish-car {
		display: inline-block;
	}
}

@media (max-width: 449px) {
	.col-xs-6.car-list {
		width: 100%;
	}
}

/* GOOGLE MAP */

.angular-google-map-container {
	height: 400px;
}

.marker_labels {
	color: #5e5e5e;
	background-color: $cgp_white;
	padding: 3px;

	/* margin-top: -100px !important;
height: 50px; */
}

/* ANIMACIJE */

article {
	.animate-container {
		overflow: hidden;
		max-height: 400px;
		cursor: pointer;
		transition: 525ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
	}

	.animate {
		&.ng-enter {
			transition: 525ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;

			.most-viewed-sub-img {
				transition: 525ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
			}
		}

		&.ng-leave {
			transition: 525ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;

			.most-viewed-sub-img {
				transition: 525ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
			}

			&.ng-leave-active {
				max-height: 0;
				top: 0;
				padding: 0;
			}
		}

		&.ng-enter {
			max-height: 0;
			top: 0;
			padding: 0;

			&.ng-enter-active {
				max-height: 400px;
				top: 0;
			}
		}

		&.ng-leave {
			max-height: 400px;
			top: 0;

			&.ng-leave-active .most-viewed-sub-img {
				opacity: 0;
			}
		}

		&.ng-enter {
			.most-viewed-sub-img {
				opacity: 0;
			}

			&.ng-enter-active .most-viewed-sub-img {
				opacity: 1;
			}
		}

		&.ng-leave .most-viewed-sub-img {
			opacity: 1;
		}
	}

	.animate-assets {
		&.ng-enter {
			transition: 525ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
		}

		&.ng-leave {
			transition: 525ms cubic-bezier(0.25, 0.25, 0.75, 0.75) all;

			&.ng-leave-active {
				max-height: 0;
			}
		}

		&.ng-enter {
			max-height: 0;

			&.ng-enter-active {
				max-height: 400px;
			}
		}

		&.ng-leave {
			max-height: 400px;
		}
	}
}

@media screen and (max-width: 992px) {
	article .animate-container {
		max-height: 430px;
	}
}

/* PRODANI MODELI */

.model-img img.prodano {
	display: block;
	position: absolute;
	width: 110px;
	margin: 5px;
	z-index: 600;
}

.item img.prodano {
	display: block;
	position: absolute;
	bottom: 0;
	z-index: 600;
	left: 4%;
	width: 90px;
	max-width: 350px;
	top: 45px;
}

@media (min-width: 515px) {
	.item img.prodano {
		width: 120px;
	}
}

@media (min-width: 767px) {
	.item img.prodano {
		width: 16%;
	}
}

@media (min-width: 1060px) {
	.item img.prodano {
		width: 20%;
	}
}

.car-model .model-content {
	min-height: 195px;
	padding-bottom: 0;

	mark {
		font-size: 17px;
		letter-spacing: -1px;
	}
}

@media screen and (max-width: 991px) {
	.car-model .model-content {
		min-height: 250px;
	}
}

@media screen and (max-width: 767px) {
	.car-model .model-content {
		min-height: 195px;
	}
}

@media screen and (max-width: 480px) {
	.car-model .model-content {
		min-height: 100%;
	}
}

.interior-list .model-content .most-viewed-highlight > {
	strong {
		line-height: 18px;
	}

	mark {
		line-height: 18px;

		.normal {
			color: black;
			font-size: 13px;
			font-weight: 200;
		}
	}
}

/* .bordered .model-content { padding-bottom: 35px; } */

.bordered .model-content {
	padding-bottom: 0;
}

.column-text li span,
.animate-assets li span,
.model-content li span {
	display: block;
}

.column-text li span.primary,
.animate-assets li span.primary,
.model-content li span.primary {
	font-weight: bolder;
}

/* body .watch-right {
	margin-right: 0;
} */

/* HUDOBRO CELOTEN HEADER */

#top {
	#logo a.tab {
		position: relative;
		height: 41px;
		display: inline-block;
		cursor: pointer;

		span {
			color: black;
			padding: 10px;
			font-weight: bold;
			position: relative;
		}

		&:hover {
			text-decoration: none;

			span {
				color: $cgp_primary;
			}
		}

		&.active::before {
			content: "";
			width: 100%;
			height: 7px;
			background-color: $cgp_primary;
			position: absolute;
			bottom: 0;
		}
	}

	#top-button {
		a:hover {
			text-decoration: none;
		}

		span {
			color: $cgp_primary;
		}

		i.glyphicon {
			color: $cgp_primary;
			width: 13px;
			margin-left: 10px;
		}
	}

	#logo .dropdown ul {
		width: 100%;
		margin-top: 22px;

		li {
			display: inline-block;
			width: 100%;
			color: $cgp_black;
			padding: 5px 10px;

			&:hover {
				color: $cgp_primary;
			}
		}
	}
}

/* 1% */

section {
	.info {
		margin-top: 40px;
		color: #353535;
		font-size: 11px;
	}

	.izracuni {
		margin-top: 10px;

		.procent {
			height: 125px;
		}

		.box {
			background-color: #ececec;
			padding: 5px 10px;
			height: 100%;

			> p {
				height: 56px;
			}
		}

		p {
			font-size: 17px;
			padding-top: 10px;
		}

		b {
			font-size: 19px;
			display: inline-block;
			margin: 8px 0;

			&.normal {
				font-weight: 200;
			}
		}
	}
}

/* 767px */
@media screen and (max-width: 767px) {
	section .izracuni .box > p {
		min-height: 4.5em;
	}
}

/* 544px */
@media screen and (max-width: 544px) {
	section .izracuni {
		.procent {
			height: auto;
			margin-bottom: 15px;
		}

		.box {
			height: auto;

			> p {
				height: auto;
				min-height: 100%;
			}
		}
	}
}

/* CENIK IN KATALOG */

.cenik-katalog a {
	> div {
		background-color: #ececec;
		padding: 40px 10px;
		height: 150px;
		text-align: center;
		font-weight: 700;

		i {
			display: block;
			margin-bottom: 10px;
			font-size: 25px;
			line-height: 25px;
		}
	}

	&:hover {
		text-decoration: none;
	}
}

@media screen and (max-width: 544px) {
	/* .cenik-katalog .cenik-item {margin-bottom: 30px;} */
	.cenik-katalog a > div {
		height: auto;
		padding: 15px 10px;
		margin-bottom: 15px;
	}
}

#most-deliverable.middle,
#most-deliverable-m.middle {
	display: table;
	margin: 0 auto;
}

/* PONUDBA 120 LET - LIST MAIN PIC */

.image-container .carousel-caption.main.hudobro > div {
	color: $cgp_black;
}

.main .carousel-caption.main.hudobro {
	margin-left: 30%;
	width: 65%;
}

@media (min-width: 380px) {
	.main .carousel-caption.main.hudobro {
		margin-left: 40%;
		width: 50%;
	}
}

@media (min-width: 767px) {
	.main .carousel-caption.main.hudobro {
		margin-left: 52%;
		margin-top: 2%;
		width: 40%;
	}
}

.carousel-caption.main.hudobro .obletnica {
	h1 {
		font-family: "Skoda ExtraBold";
		font-size: 30px;
	}

	p {
		font-size: 12px;
	}

	img {
		width: 100px;
		margin-top: 0;
		max-width: 350px;
	}
}

@media (min-width: 767px) {
	.carousel-caption.main.hudobro .obletnica h1 {
		font-size: 5.3vw;
	}
}

@media (min-width: 2000px) {
	.carousel-caption.main.hudobro .obletnica h1 {
		font-size: 120px;
	}
}

@media (min-width: 767px) {
	.carousel-caption.main.hudobro .obletnica p {
		font-size: 2vw;
	}
}

@media (min-width: 2000px) {
	.carousel-caption.main.hudobro .obletnica p {
		font-size: 39px;
	}
}

@media (min-width: 767px) {
	.carousel-caption.main.hudobro .obletnica img {
		width: 50%;
		margin-top: 20px;
	}
}

/*  FINANCIRANJE 1% - LIST MAIN PIC */

.main {
	&.procent .carousel-caption.main .procent {
		padding-left: 3%;
		padding-top: 0;
	}

	&.polovicka .carousel-caption.main {
		width: 100%;

		//.polovicka {}
	}
}

@media (min-width: 767px) {
	.main.procent .carousel-caption.main .procent {
		padding-left: 2%;
		padding-top: 14px;
	}
}

@media (min-width: 940px) {
	.main.procent .carousel-caption.main .procent {
		padding-left: 2%;
		padding-top: 30px;
	}
}

@media (min-width: 1008px) {
	.main.procent .carousel-caption.main .procent {
		padding: 0;
	}
}

/* .procent .carousel-caption.main .procent h1 {
	font-family: 'Skoda ExtraBold';
	font-size: 30px;
} */

/* @media (min-width: 767px) {
	.procent .carousel-caption.main .procent h1 {
		font-size: 5.3vw;
	}
}
@media (min-width: 2000px) {
	.procent .carousel-caption.main .procent h1 {
		font-size: 120px;
	}
} */

/* .procent .carousel-caption.main .procent p {
	font-size: 12px;
	max-width: 169px;
}
@media (min-width: 767px) {
	.procent .carousel-caption.main .procent p {
		font-size: 2vw;
		max-width: initial;
	}
}
@media (min-width: 2000px) {
	.procent .carousel-caption.main .procent p {
		font-size: 39px;
	}
} */

/*  FINANCIRANJE POLOVIČKA - LIST MAIN PIC */

@media (min-width: 767px) {
	.main.polovicka .carousel-caption.main .polovicka {
		margin-top: 58px;
	}
}

@media (min-width: 1008px) {
	.main.polovicka .carousel-caption.main .polovicka {
		margin-top: 0;
		top: 33%;
		transform: translate(0, -50%);
	}
}

@media (min-width: 1442px) {
	.main.polovicka .carousel-caption.main .polovicka {
		top: 18%;
	}
}

@media (min-width: 2000px) {
	.main.polovicka .carousel-caption.main .polovicka {
		max-width: 50%;
		margin: 0 auto;
	}
}

.polovicka .carousel-caption.main .polovicka {
	h1 {
		font-family: "Skoda ExtraBold";
		font-size: 30px;
	}

	p {
		font-size: 12px;
	}
}

@media (min-width: 646px) {
	.polovicka .carousel-caption.main .polovicka h1 {
		max-width: 100%;
		margin: 0;
		margin-bottom: 13px;
	}
}

@media (min-width: 767px) {
	.polovicka .carousel-caption.main .polovicka h1 {
		font-size: 5.3vw;
		max-width: 100%;
		margin: 0;
		margin-bottom: 13px;
	}
}

@media (min-width: 2000px) {
	.polovicka .carousel-caption.main .polovicka h1 {
		font-size: 110px;
	}
}

@media (min-width: 767px) {
	.polovicka .carousel-caption.main .polovicka p {
		font-size: 2vw;
	}
}

@media (min-width: 2000px) {
	.polovicka .carousel-caption.main .polovicka p {
		font-size: 39px;
	}
}

/*  PONUDBA EASY - LIST MAIN PIC */

.main.easy .carousel-caption.main {
	width: 100%;

	.easy {
		padding-top: 0;
		margin: 0 auto;
		max-width: 50%;
	}
}

@media (min-width: 767px) {
	.main.easy .carousel-caption.main .easy {
		padding-top: 5%;
	}
}

@media (min-width: 1447px) {
	.main.easy .carousel-caption.main .easy {
		padding-top: 0;
	}
}

@media (min-width: 2000px) {
	.main.easy .carousel-caption.main .easy {
		padding-left: 24%;
	}
}

.easy .carousel-caption.main .easy {
	h1 {
		font-family: "Skoda ExtraBold";
		font-size: 30px;
	}

	p {
		font-size: 12px;
	}
}

@media (min-width: 767px) {
	.easy .carousel-caption.main .easy h1 {
		font-size: 5.3vw;
		max-width: 100%;
	}
}

@media (min-width: 2000px) {
	.easy .carousel-caption.main .easy h1 {
		font-size: 120px;
	}
}

@media (min-width: 767px) {
	.easy .carousel-caption.main .easy p {
		font-size: 2vw;
	}
}

@media (min-width: 2000px) {
	.easy .carousel-caption.main .easy p {
		font-size: 39px;
	}
}

/*  PONUDBA FAMILY - LIST MAIN PIC */

.main.family .carousel-caption.main {
	width: 100%;

	.family {
		padding-left: 3%;
		padding-top: 0;
	}
}

@media (min-width: 767px) {
	.main.family .carousel-caption.main .family {
		padding-left: 5%;
		padding-top: 14px;
	}
}

@media (min-width: 940px) {
	.main.family .carousel-caption.main .family {
		padding-top: 30px;
	}
}

@media (min-width: 1008px) {
	.main.family .carousel-caption.main .family {
		padding-left: 30px;
	}
}

.family .carousel-caption.main .family {
	h1 {
		font-family: "Skoda ExtraBold";
		font-size: 30px;
		color: $cgp_white;
	}

	p {
		font-size: 12px;
		max-width: 169px;
	}
}

@media (min-width: 767px) {
	.family .carousel-caption.main .family h1 {
		font-size: 5.3vw;
	}
}

@media (min-width: 2000px) {
	.family .carousel-caption.main .family h1 {
		font-size: 120px;
	}
}

@media (min-width: 767px) {
	.family .carousel-caption.main .family p {
		font-size: 2vw;
		max-width: initial;
	}
}

@media (min-width: 2000px) {
	.family .carousel-caption.main .family p {
		font-size: 39px;
	}
}

.brez-visine {
	min-height: 500px;
}

@media (max-width: 992px) {
	.car-slide .ncap {
		position: absolute;
		width: 20%;
		left: 10%;
    max-height: 100px;
	}
}

@media (min-width: 993px) {
	.car-slide .ncap {
		position: relative;
		width: 20%;
		margin-left: 10%;
		margin-bottom: 10%;
	}
}

.nopadding-left {
	padding-left: 0;
}

.margin-top-15 {
    margin-top: 15px;
}

.nomargin-top {
	margin-top: 0 !important;
}

.bon-info {
	font-size: 12px;
	margin-top: 12px;
	font-weight: 700;
}

/* PROCENTPLUS */

body rzslider .rz-ticks {
	padding: 0;
}

/* ANIMATE LIST GROUP PICTURE OPACITY */

#most-deliverable > li img,
#most-deliverable-m > li img {
	transition: all 500ms ease-out;
	margin: 0 auto;
}

/* SUBINFO */

.selected-subinfo {
	margin-top: -30px;
	margin-bottom: 30px;

	a {
		color: $cgp_black;
	}
}

.black-clr {
	color: $cgp_black;
}

/* DISCLAIMER */

.clr-disclaimer {
	position: absolute;
	height: 100px;
	bottom: 15px;
	font-size: 14px;
	color: $cgp_gray;
	width: 50%;
	left: 40%;
}

@media (max-width: 992px) {
	.clr-disclaimer {
		bottom: -33%;
	}
}

@media (max-width: 430px) {
	.clr-disclaimer {
		font-size: 11px;
		bottom: -45%;
	}
}

@media (min-width: 431px) and (max-width: 767px) {
	.clr-disclaimer {
		bottom: -24%;
	}
}

.text-overline {
	text-decoration: line-through;
}

/* SWITCHERS
.radio-wrapper.neutral { background-color:  } */

/* RAZNO */

.car-slide .car-pricing .info p {
	color: $cgp_gray;
	font-size: 11px;
}

/* EXTRA NOTIFICATION */

footer .extra-notif {
	padding-bottom: 15px;

	a {
		color: inherit;
		font-size: inherit;
		text-decoration: underline;
	}
}

/* HUDOBRO PRICE BORDER */

.hudobro-price {
	border: 1px solid $cgp_gray_light;
	padding: 15px;
}

/* OCTAVIA SUBPAGE subtitle */

#octavia_subtitle {
	color: #d0cfcf;
	font-weight: 400;
	font-size: 25px;
	line-height: 1em;
	margin-bottom: 0.3em;
	text-align: center;
}

/* min znesek financiranja warning */

.min_fin_wrapp {
	position: relative;

	.min_fin {
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		opacity: 0.9;
		background-color: #ececec;
		z-index: 99;

		p {
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
			width: 100%;
		}
	}
}

/* brez skrbi finacniranje zavarovanja */

.izracuni .box {
	.right-plus {
		position: relative;

		&::after {
			position: absolute;
			content: "+";
			color: $cgp_primary;
			width: 35px;
			height: 35px;
			right: 0;
			font-size: 35px;
			top: 15px;
		}
	}

	.right-equals {
		position: relative;

		&::after {
			position: absolute;
			content: "=";
			color: $cgp_primary;
			width: 35px;
			height: 35px;
			right: 0;
			font-size: 35px;
			top: 15px;
		}
	}
}

.btn-switcher .btn-group {
	width: 100%;

	.btn {
		background-color: transparent;
		color: #6c6c6c;
		min-width: 33%;
		min-height: 52px;
		border: 1px solid #b2b2b2;
		font-weight: 400;

		&.active {
			background-color: $cgp_primary;
			color: $cgp_white;
		}
	}
}

/* FAKER */

.faker {
	.drop-selection div,
	.drop-value {
		font-weight: 300;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	cursor: default;
	height: 53px;
	position: relative;
	width: 100%;
	-webkit-touch-callout: none;
	user-select: none;

	&.faker-search.open .drop-value {
		z-index: 11;
	}

	&.faker-focused .drop-value {
		border-color: #66afe9;
		outline: 0;
		box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
			0 0 8px rgba(102, 175, 233, 0.6);
	}

	&.open .drop-selection {
		border-bottom: 1px solid #e7e7e7;
		display: block;
		height: auto;
		max-height: 400px;
		opacity: 1;
		overflow: auto;
		border-left: 1px solid #e7e7e7;
		border-right: 1px solid #e7e7e7;
		z-index: 11;
	}

	.drop-handle {
		top: 0;
		background-color: $cgp_white;
		border: 1px solid #e6e7e8;
		cursor: pointer;
		position: absolute;
	}

	.drop-value {
		top: 0;
		background-color: $cgp_white;
		border: 1px solid #e6e7e8;
		cursor: pointer;
		position: absolute;
		display: inline-block;
		left: 0;
		line-height: 52px;
		overflow: hidden;
		padding-right: 45px;
		vertical-align: top;
		width: 100%;
		z-index: 2;
		padding-left: 1em;
		color: #949698;
		height: 53px;

		input[name="faker-search"] {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			z-index: 150;
			padding: 0 25px;
		}
	}

	.drop-handle {
		right: 0;
		width: 53px;
		height: 53px;
		z-index: 10;

		&::after {
			content: "";
			height: 9px;
			position: absolute;
			width: 14px;
			display: block;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiPjxkZWZzPjxwYXRoIGlkPSJmd2Y4YSIgZD0iTTYyMDcuNTIgMzU2MS40NGMwLS4xLS4wNS0uMjMtLjEzLS4zbC0uNjctLjY4YS40Ni40NiAwIDAgMC0uMy0uMTNjLS4xMSAwLS4yNC4wNS0uMzIuMTNsLTUuMjYgNS4yNy01LjI3LTUuMjdhLjQ2LjQ2IDAgMCAwLS4zLS4xMy40NC40NCAwIDAgMC0uMzEuMTNsLS42Ny42N2EuNDYuNDYgMCAwIDAtLjE0LjMxYzAgLjEuMDYuMjMuMTQuMzFsNi4yNCA2LjI0Yy4wOC4wOC4yLjE0LjMuMTQuMTIgMCAuMjQtLjA2LjMyLS4xNGw2LjI0LTYuMjRjLjA4LS4wOC4xMy0uMi4xMy0uM3oiLz48L2RlZnM+PGc+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYxOTQgLTM1NjApIj48dXNlIHhsaW5rOmhyZWY9IiNmd2Y4YSIvPjwvZz48L2c+PC9zdmc+")
				(center / 14px) 9px no-repeat;
			font-family: sans-serif;
			font-stretch: extra-expanded;
			font-size: 16px;
		}
	}

	.drop-selection {
		background-color: $cgp_white;
		cursor: pointer;
		height: 0;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		top: 53px;
		width: 100%;
		z-index: 9;

		div {
			letter-spacing: 0.1em;
			line-height: 1.25em;
			padding-left: 15px;
			padding: 0.5em 15px;
			width: 100%;
			white-space: normal;
			font-size: 14px;
			cursor: pointer;

			&.active,
			&:hover {
				background-color: #959698;
				color: $cgp_white;
			}

			&.rel-search {
				display: none;
			}

			&.rel-disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
	}

	select {
		display: block;
		opacity: 0;
		overflow: hidden;
		position: absolute;

		option {
			display: none;
		}
	}

	.drop-value:hover,
	&.open .drop-value {
		color: #959698;
	}
}

.faker-small .faker .faker .drop-selection {
	max-height: 200px;
}

.faker {
	.drop-handle::after {
		transform: rotate(0);
		transition: all 0.3s ease;
	}

	&.open .drop-handle::after {
		transform: rotate(180deg);
	}
}

.validator.invalid {
	border: 1px solid rgb(239, 169, 169);
}

.faker {
	.drop-value {
		min-height: 34px;
		border: 1px solid #b2b2b2;
		padding: 14px 20px;
		display: inline-block;
		width: 100%;
		overflow: hidden;
		position: relative;
		z-index: 1;
		box-sizing: border-box;
		box-shadow: none;
		border-radius: 4px;
		color: #6c6c6c;
		font-family: inherit;
		font-size: inherit;
		line-height: 20px;
		-webkit-font-smoothing: inherit;
		max-height: 50px;
		background: $cgp_white;
	}

	.drop-handle {
		//background: url("/media/icons/arrow-down-faker.svg") no-repeat;
		background: url("data:image/svg+xml,#{$icon_arrow_down_small}") no-repeat scroll center / 100% auto;
		top: 50%;
		right: 17px;
		margin-top: -3px;
		width: 17px;
		height: 9px;
		z-index: 9;
		border: 0;

		&::after {
			content: none;
		}
	}

	&.open .drop-selection {
		top: 50px;
	}
}

.info .btn {
	height: 50px;

    &.reserved {
		cursor: not-allowed;
		background-color: $cgp_gray;
		border-color: $cgp_gray;
	}
}

.new-boxes-info {
	hr {
		margin-top: 32px;
		margin-bottom: 32px;
	}

	.izracuni {
		margin-top: 22px;
	}

	.min_fin_wrapp .izracuni:first-child {
		margin-top: 40px;
	}

	.izracuni {
		&.big-box .box > p {
			height: auto;
			margin-bottom: 10px;
			padding-top: 27px;
		}

		.box > p {
			margin-bottom: 15px;
		}

		.col-sm-3.procent {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.select-p {
		padding: 25px 0 10px;
	}

	.izracuni.calculation-box .box {
		padding: 20px 22px 12px;

		p {
			padding-top: 15px;
		}

		.col-sm-3:last-child b {
			font-size: 32px;
			margin-top: 0;
		}
	}
}

/*		100% WIDTH BOXES NEED CLASS "big-box"	*/

/*****		HR PRED SERVISIRANJEM MORA MET MARGIN 40px 0 35px; in isto pod SERVISIRANJEM	**/

/*		LAST BOX WITH PLUSES needs CLASS "calculation-box"	*/

.insurance-calc span {
	display: inline-block;
	margin: 0 0 12px;
}

/* MODAL PONUDBA TRGOVCA */

.modal-ponudba-trgovca {
	.modal-dialog {
		width: 620px;
	}

	.modal-header .close {
		margin-top: -5px;
		margin-right: -15px;
		font-weight: 400;
		font-size: 65px;
		z-index: 999;

		span {
			font-size: 90px;
		}
	}

	h4.modal-title {
		font-size: 30px;
		font-weight: 700;
		line-height: 38px;
		max-width: 450px;
	}

	.modal-body p {
		max-width: 350px;
		margin-bottom: 32px;
		font-size: 16px;
		line-height: 24px;
	}

	hr {
		display: none;
	}

	label {
		color: black;
		font-size: 12px;
		font-weight: 700;
		line-height: 23px;
	}
}

@media (max-width: 620px) {
	.modal-ponudba-trgovca .modal-dialog {
		width: 400px;
	}
}

html body .modal-ponudba-trgovca form input.form-control {
	border-radius: 4px;
	border: 1px solid #b2b2b2;
	font-size: 12px;
	font-weight: 400;

	//line-height: 38px; // Causing problems in Safari browser!
	max-height: 41px;

	&.ng-touched.ng-invalid {
		border: 1px solid $validation_failed;
	}
}

.modal-ponudba-trgovca {
	.glyphicon {
		width: 30px;
		height: 30px;
		position: absolute;
		border-radius: 4px;
		border: 1px solid #b2b2b2;
		top: 2px;

		&::before {
			background:  url("data:image/svg+xml,#{$icon_checkbox}") no-repeat scroll center (center / 70%) auto;
			transform: scale(0);
			width: 21px;
			height: 21px;
			position: absolute;
			top: 4px;
			left: 4px;
		}

		&.glyphicon-unchecked::before {
			content: "";
		}

		&.glyphicon-check::before {
			transform: scale(1);
			content: "";
		}

		+ span {
			display: inline-block;
			padding-left: 41px;
			color: #777576;
			font-size: 12px;
			font-weight: 400;
			line-height: 16px;
			padding-bottom: 30px;
		}
	}

	.validator {
		border-bottom: 1px solid #b2b2b2;
	}

	.modal-header {
		padding-top: 32px;
		padding-left: 40px;
		padding-right: 40px;
	}

	/* .btn-default {
		height: 50px;
		border-radius: 2px;
		padding-left: 21px;
		padding-right: 21px;
	} */
	.modal-body {
		padding-left: 40px;
		padding-right: 40px;
	}

	.modal-footer {
		padding: 0 40px 40px;
		border-top: none;
	}

	.modal-body {
		min-height: 400px;

		h4 {
			padding-top: 80px;
			max-width: 400px;
		}

		p.full-width-p {
			max-width: 100%;
			font-size: 16px;
			line-height: 27px;
			color: black;

			&.subtitle {
				font-size: 18px;
				font-weight: 800;
			}
		}

		ul.info-list li {
			padding-left: 15px;
			position: relative;
			margin-bottom: 20px;

			&::before {
				content: "";
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: $cgp_primary;
				position: absolute;
				top: 6px;
				left: 0;
			}
		}
	}

	// .faker {
	// 	.drop-value {
	// 		border: 1px solid #b2b2b2;
	// 		max-height: 41px;
	// 		padding: 10px 20px;
	// 		span {
	// 			font-size: 12px;
	// 		}
	// 		&.invalid span {
	// 			color: $cgp_gray;
	// 		}
	// 	}
	// 	.drop-handle {
	// 		top: 21px;
	// 		width: 16px;
	// 		height: 10px;
	// 	}
	// 	&.open .drop-selection {
	// 		top: 41px;
	// 	}
	// }
	.faker {
		.drop-value {
			border: 1px solid #b2b2b2;
			max-height: 32px;
			padding: 6px 20px;

			span {
				font-size: 12px;
			}

			&.invalid span {
				color: $cgp_gray;
			}
		}

		.drop-handle {
			top: 16px;
			width: 16px;
			height: 10px;
		}

		&.open .drop-selection {
			top: 34px;
		}
	}
}

.faker.open .drop-selection > div[disabled] {
	cursor: not-allowed;
}

/* car detail */

.car-slide .oglas p {
	&.ln-1em {
		line-height: 1em;
	}

	&.ln-2em {
		line-height: 2em;
	}
}

/* DESIGN UPGRADE 25.04.2019 */

#most-deliverable-title,
#most-deliverable-title-m {
	font-size: 52px;
	margin-bottom: 0.7em;
}

@media (max-width: 768px) {
	#most-deliverable-title,
	#most-deliverable-title-m {
		font-size: 32px;
	}
}

#selected-model .selected-heading {
	font-size: 54px;
}

.zaloga_title {
	color: $cgp_black;
	font-weight: 700;
	font-size: 30px;
	line-height: 40px;
	margin-bottom: 45px;
	text-align: center;

	&::after {
		content: "";
		position: absolute;
		bottom: -15px;
		left: 50%;
		width: 40px;
		height: 3px;
		background-color: $cgp_primary;
		transform: translateX(-50%);
	}
}

@media (max-width: 768px) {
	.zaloga_title {
		font-size: 24px;
	}
}

.zaloga_subtitle {
	max-width: 490px;
	color: $cgp_black;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin: 0 auto;
	margin-bottom: 30px;

    &.procent-maxwidth {
        max-width: 620px;
    }
}

/* .carousel-caption .car-price .financiranje_scroll {
	width: 100%;
	background-color: transparent;
	color: $cgp_primary;
	text-transform: uppercase;
	border: 2px solid $cgp_primary;
	margin-top: 2px;
	margin-bottom: 5px;
	border-radius: 0;
	height: 35px;
	font-size: 14px;
	&:hover {
		background-color: $cgp_primary;
		color: $cgp_white;
	}
} */

/* zaloga car detail equipment header */

.detail-extra-eq {
	margin-bottom: 20px;
	display: block;
}


/*******************************************************************************
* OVERLAY
*******************************************************************************/
#busyScreen {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999999999;
  text-align: center;
  background-color: black;
  opacity: 0.7;
  cursor: wait; }

#busyScreen img {
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100px; }


//*******************************************************************************
// X.X - PLUGINS
//*******************************************************************************

rzslider[disabled] .rz-pointer,
rzslider .rz-bar.rz-selection,
rzslider .rz-pointer,
rzslider .rz-ticks .tick.selected { background-color: $cgp_primary; }

rzslider .rz-pointer {
  top: -8px;
  width: 20px;
  height: 20px;

  &::after {
    display: none;
  }
}

.toast-success {
	//background-color: #51A351; // Default color from plugin
	background-color: scale-color($cgp_primary, $lightness: -4%);
}

.slick-slider {
  margin-bottom: 0;
}

/*******************************************************************************
* CONFIRMATION PAGE
*******************************************************************************/
section.confirmation {
    h5 {
        font-size: 30px;
        font-weight: 700;
        line-height: 38px;
        margin-bottom: 20px;
        text-align: center;
    }

    p {
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 24px;
    }

    ul {
        margin-bottom: 20px;

        li {
            list-style: square;
            margin-left: 20px;
        }
    }
}

/*******************************************************************************
* KONFIGURATOR NO RESULTS
*******************************************************************************/
.konfigurator-car-not-found  {
  .slider-swipe {
    background-color: $cgp_gray_extra_light;
    padding: 15px;
  }

  .car-cta {
    margin-top: 30px;

    button {
      /* background-color: $cgp_primary;
      color: $cgp_white;
      border: none;
      width: 198px;
      height: 50px;
      border-radius: 2px; */
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
      margin-bottom: 15px;
    }
  }
}

// COOKIES
.cookies {
	h1, h2, h3 {
		margin: 20px 0;
	}

	p {
		margin: 8px 0;
	}
}

.slider-deposit-helper {
	@include bp-up(sm) {
		margin-left: -21px;
	}

	&--center {
		@include bp-up(sm) {
			margin-left: 21px;
		}
	}
}

// info row
.d-flex {
	@include bp-up(sm) {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		button {
			float: none !important;
			flex-shrink: 0;
		}
	}
}
