//*******************************************************************************
// 1.0 - MOST VIEWED
//*******************************************************************************

.most-viewed {

	// ##################################################

	// Listing
	&-list {
		@include flexbox();
		@include flex-flow(row wrap);
		margin: 0 -15px;
        justify-content: center;
	}

	// ##################################################
	// Single item column

	&-item {
		overflow: hidden;
		position: relative;
		margin: 0 15px;
		width: calc(33.33% - 30px);
		@include flexbox();
		@include flex-flow(column);

		// Mobile
		@include bp-down(sm) {
			width: 100%;
			margin-bottom: 40px;
		}

	}

	// ##################################################
	// Images

	&-img,
	&-sub-img { width: 100%; }

	&-img {
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 225px;
		position: relative;
        cursor: pointer;
	}

	&-sub-img {
		width: 100px;
		height: 100px;
		line-height: 100px;
		border-radius: 50%;
		right: 20px;
		bottom: 20px;
		position: absolute;
		background-color: $cgp_white;
		overflow: hidden;

		img {
			height: auto;
			position: relative;
			top: 50%;
			width: 100%;
			@include translate(0px, -50%);
		}

	}

	// ##################################################

	&-content {
		background-color: #ececec;
		font-size: 15px;
		text-align: left;
		width: 100%;
		padding: 15px 10px;
        cursor: pointer;
		@include flexbox();
		@include flex(1 0 auto);
		@include flex-flow(column);
		@include justify-content(space-between);

		h1 {
			font-weight: 700;
			font-size: 24px;
			line-height: 1.2em;
			margin-bottom: 0.1em;
		}

		h1 + p {
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 30px;
		}

		ul li {
			font-size: 12px;
			line-height: 1.5em;
			margin-bottom: 10px;
		}

		ul li span {
			font-weight: 700;
			font-size: inherit;
			font-weight: 700;
			line-height: inherit;
		}

		ul li span + span {font-weight: 400;}


		li {
			color: $cgp_primary;

			span { color: $cgp_black; }

		}

	}

	// ##################################################

	&-model-name { margin-bottom: 0.33em; }

	&-model-text {}

	// ##################################################
	// Pricing

	&-price {
		@include flexbox();
		@include flex-flow(row wrap);
		@include justify-content(space-between);
		@include align-items(center);
		padding-top: 20px;

		// ##################################################

		&--regular, &--special {
			line-height: 1em;
			font-weight: 700;
			white-space: nowrap;
			margin: 5px 0;
		}

		&--regular {
			margin-right: 1em;
		}

		&--special {
			color: $cgp_primary;
			text-align: right;
			font-size: 20px;
		}

		.normal {
			color: black;
			font-size: 13px;
			font-weight: 200;
		}

	}


	// ##################################################
	// Triggers for opening overlay

	&-trigger {
		background-color: $cgp_black;
		color: $cgp_white;
		cursor: pointer;
		height: 30px;
		padding: 0 15px;
		@include flexbox();
		@include align-items(center);
		@include justify-content(space-between);

		// ##################################################

		&--bottom {}

		// ##################################################

		&--top {
			position: sticky;
			top: 0;
		}

	}


	// ##################################################
	// Overlay for additional info

	&-overlay {
		background-color: $cgp_black;
		color: $cgp_white;
		font-size: 14px;
		line-height: 1.45em;
		padding: 0 20px 20px;

		overflow: auto;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: 2;

		visibility: hidden;
		opacity: 0;
		@include transition(opacity 250ms ease-in, visibility 0s linear 250ms);

		// ##################################################
		// When we open additional info

		.is-open & {
			opacity: 1;
			visibility: visible;
			@include transition(opacity 250ms ease-in, visibility 0s linear);
		}

		// ##################################################

		strong {
			line-height: 30px;
		}

		ul { padding-left: 15px; }

		li {
			color: $cgp_primary;
			list-style: outside disc;
			padding: 2px 0;
		}

		span {
			color: $cgp_white;

			&.primary { font-weight: 700; }

			& + span {display: block;}

		}

		// ##################################################

	}

	// ##################################################


}





//*******************************************************************************
// 1.2 - MOST VIEWED - Single car
//*******************************************************************************

.bordered {
	border: 1px solid #ececec;
}

.bordered .selected-most-viewed-img {
	height: 360px;
	margin: 20px 0;
}


.bordered .model-content {
	background-color: #ececec;
	min-height: 360px;
	border: 1px solid #ececec;
	text-align: left;
	padding: 15px;
	box-sizing: border-box;
	font-size: 15px;

	h1 {
		font-weight: 700;
		font-size: 24px;
		line-height: 1.2em;
		margin-bottom: 0.1em;
	}

	h1 + p {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 30px;
	}

	li {
		font-size: 12px;
		line-height: 1.5em;
		margin-bottom: 10px;
	}

	// ##################################################
	// Pricing

	.most-viewed-price {
		@include align-items(flex-start);
	}

	.most-viewed-price--regular,
	.most-viewed-price--special {
		width: 50%;
		white-space: normal;

		// Mobile
		@include bp-down(sm) { width: 100%; }

	}

	// ##################################################

	.most-viewed-price--regular {margin-right: 0;}

	.most-viewed-price--special {
		text-align: center;

		// Mobile
		@include bp-down(sm) { text-align: left; }

	}

	// ##################################################

}
