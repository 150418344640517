@charset "UTF-8";

/* Custom CSS */

html,
body {
	font-family: $font_primary;
	color: $cgp_black;
	width: 100%;
	line-height: 1.4;
	height: 100%;
}

.green {
	color: $cgp_primary;
}

.red {
	color: #f00;
}

.black-button {
	background-color: $cgp_black;
	border-color: $cgp_black;
}

.black-button:hover {
	background-color: $cgp_gray;
	border-color: $cgp_gray;
}

h1,h2,h3,h4,h5,h6,strong,b,mark { font-weight: 700; }

.glyphicon, .icon, .radio-wrapper label, .car-model, .selected-back, #most-deliverable, #most-deliverable-m, .wish-car { cursor: pointer; }


/* TOP BAR */
#top-drop-down {
	color: $cgp_white;
	text-align: center;
	background-color: $cgp_black;
}

#top,
#top-pick {
	/* max-width: 1440px; */
	margin: 0 auto;
}

main {
	max-width: 1440px;
	margin: 0 auto;
}

#top {
	border-bottom: 1px solid $cgp_gray;
}

#top a,
#top a:active,
#top a:hover,
#top a:focus {
	outline: 0;
	border: 0;
}

.top-right {
	text-align: right;
	padding-right: 0;
}

.top-right > .row {
	margin-right: 0;
}

#top-button {
	padding-right: 10px;
	box-sizing: border-box;
	display: inline-block;
	height: 60px;
	white-space: nowrap;
	line-height: 60px;
	border-left: 1px solid $cgp_gray;
	cursor: pointer;

	/* seznam želja */
}

#top-button > button {
	background-color: rgba(255, 255, 255, 0);
	border: 0;
}

#top-button .dropdown-menu {
	margin: 0;
	padding: 0;
	border-radius: 0;
	border-radius: 0;
	border-radius: 0;
	top: 100%;
	box-shadow: none;
	box-shadow: none;
	box-shadow: none;
}

#top-button li {
	box-sizing: border-box;
	padding: 20px;
}

#top-button span {
	padding-left: 10px;
}

#top-button .icon {
	color: #cdcdcd;
}

#top-button .seznam-zelja-besedilo {
	color: $cgp_primary;
}

#top-button .glyphicon-menu-down {
	color: $cgp_black;
}

#top-button .wish-car {
	display: inline-block;
	border: 1px solid #ececec;
}

#top-button .wish-car .wish-top {
	border-bottom: 1px solid #ececec;
}

#top-button .wish-car .wish-top img {
	width: 140px;
}

#top-button .wish-car .wish-bottom {
	padding: 10px;
	box-sizing: border-box;
}

#top-button .wish-car .wish-bottom p {
	margin: 0;
	padding: 0;
	font-weight: bold;
	line-height: 20px;
}

#top-button .wish-buttons {
	line-height: 20px;
	margin-top: 10px;
}

.wish-car + .wish-car {
	margin-left: 10px;
}

#top-pick {white-space: nowrap;}

#top-pick a,
#top-pick a:active,
#top-pick a:hover,
#top-pick a:focus {
	outline: 0;
	border: 0;
}

#top-pick a {
	color: $cgp_black;
	display: block;
	font-size: 30px;
	font-weight: 400;
	line-height: 60px;
	text-decoration: none;
}

#top-pick div {
	min-height: 60px;
}

#top-pick-close {
	line-height: 10px;
}

#top-pick-close a {
	text-decoration: none;
	line-height: 60px;
	color: $cgp_black;
	font-size: 50px;
}


/* SLIDER */

.image-container {
	width: 100%;

	/* height: 370px; */
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

#carousel-counter {
	border: 1px solid $cgp_black;
	bottom: -35px;
	height: 44px;
	position: absolute;
	right: 20px;
	text-align: center;
	width: 44px;
	z-index: 9;
	line-height: 42px;
}

#carousel-counter span {
	font-weight: 200;
	font-size: 14px;
	line-height: inherit;
}


/* SLIDER CONTENT */
.car-slide {font-size: 0;}

.car-slide .car-left,
.car-slide .car-right {
	display: inline-block;
	float: none;
	height: 100%;
	vertical-align: middle;
}

.car-slide .car-left {}

.car-slide .car-right {
	padding-top: 40px;

	.model-dobavljivo {
		color: $cgp_primary;
		background-color: transparent;
		display: block;
		padding: 0;
		font-weight: 700;
		font-size: 14px;
		height: auto;
		margin-bottom: 8px;
	}
}

/* .carousel-caption {
	width: 30%;
	margin-left: 40%;
} */

.car-slide .oglas p {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.8em;
}

.car-slide .car-spec {
	border-bottom: 1px solid $cgp_gray_light;
	padding-bottom: 15px;
}

.car-slide .car-spec p {font-size: 12px;}

.car-slide .car-pricing {
	margin-bottom: 15px;
	padding: 15px 0 0;
}

.car-slide .car-price {margin-bottom: 15px;}

.car-slide .car-price.special {}

.car-slide .car-price.special em {
	color: $cgp_primary;
	font-size: 36px;
	font-weight: 700;
	line-height: 36px;
	font-style: normal;
}

/* cta */
.car-slide .car-cta {}

.car-slide .car-cta span {
	color: $cgp_primary;
	font-size: 14px;
	font-weight: 700;
	line-height: 26px;
}

.carousel-caption.main {
	margin-left: 20%;
}

.carousel-caption .oglas {
	color: $cgp_black;
	text-align: left;
}

.carousel-caption .oglas .text-on {
	display: inline;
}

.carousel-caption .oglas h1 {
	font-size: 24px;
	font-weight: 700;
	line-height: 1.2em;
}

.carousel-caption .oglas .icon-heart-o,
.carousel-caption .oglas .icon-heart {
	color: $cgp_primary;
	padding-left: 20px;
}

.carousel-caption .oglas a {
	color: $cgp_primary;
	text-decoration: none;
}

.carousel-caption .oglas span,
.carousel-caption .oglas b,
.carousel-caption .oglas strong {
	display: inline-block;
}

.carousel-caption .oglas .green {
	color: $cgp_primary;
}

.carousel-caption .oglas .highlight {
	font-size: 20px;
	font-weight: 700;
}

.carousel-caption .oglas a,
.carousel-caption .oglas a:active,
.carousel-caption .oglas a:hover,
.carousel-caption .oglas a:focus {
	outline: 0;
	border: 0;
}

.carousel-caption .oglas button {
	/* background-color: $cgp_primary;
	color: $cgp_white;
	border: none;
	width: 198px;
	height: 50px;
	border-radius: 2px; */
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
	margin-bottom: 15px;

	&.reserved {
		cursor: not-allowed;
		background-color: $cgp_gray;
	}
}

.carousel-caption .oglas button.btn-warning {
	color: $cgp_white;
    background-color: #f0ad4e;
    border-color: #eea236;
}

.carousel-caption .obletnica {
	text-align: right;
	color: $cgp_black;
}

.carousel-caption .obletnica h1 {
	font-size: 40px;
	font-weight: bold;
	margin-bottom: 15px;
}

.carousel-caption .obletnica img {
	margin-top: 20px;
}

.dodatna-oprema > div {
	padding: 5px 0 10px;
}

@media screen and (max-width: 767px) {
	.car-slide {margin-bottom: 30px;}
	.car-slide .car-left {padding: 15px 0;}

	.car-slide .car-left, .car-slide .car-right {
		display: block;
		height: auto;
	}
	.car-slide .car-left img {margin: 0 auto;}
}

@media screen and (max-width: 544px) {
	.oprema-slika {margin-top: 30px;}
}

/* CAR DESCRIPTION */
.car-description {}

#main .car-description ul {}

#main .car-description ul li {
	font-size: 12px;
	font-weight: 400;
	line-height: 1.5em;
	list-style: outside disc none;
	position: relative;
}

#main .car-description ul li span {display: inline;}

#main .car-description ul li span + span {display: block;}

/* NAVIGATION */

#main nav {
	background-color: #ececec;
}

#main nav a,
#main nav a:active,
#main nav a:hover,
#main nav a:focus {
	outline: 0;
	border: 0;
}

#main nav ul {
	margin: 0;
	padding: 0;
}

#main nav ul li {
	cursor: pointer;
	margin: 0;
	padding: 0;
	min-height: 65px;
	line-height: 65px;
	white-space: nowrap;
	display: inline-block;
	box-sizing: border-box;
}

#main nav a:link,
#main nav a:active,
#main nav a:visited,
#main nav a:hover {
	font-weight: 700;
	text-decoration: none;
	padding: 0 25px;
	color: $cgp_black;
}

#main nav .active,
#main nav ul li:hover {
	background-color: $cgp_white;
}


/* Switches */

.switch-content-3 {
	width: 50px;
	height: 25px;
	position: relative;
}

.switch-right-label-3 {
	margin-left: 8px;
}

#switch-title-3 {
	margin-bottom: 22px;
}

.switch-container {
	position: absolute;
	top: 8px;
	left: 5px;
}

.radio-wrapper {
	width: 45px;
	height: 20px;
	display: inline-block;
	vertical-align: middle;
	background: $cgp_primary;
	border-radius: 10px;
	position: relative;
}

.radio-wrapper p {
	position: absolute;
	z-index: 10;
	color: white;
	font-size: 1.7em;
	margin: 0;
	margin-top: 13px;
}

.radio-wrapper label {
	z-index: 9;
}

input[type="radio"] {
	display: none;
}

.neutral + label {
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	position: absolute;
	left: 33%;
	transition: -webkit-transform 1s;
	transition: transform 1s;
}

.neutral:checked + label {
	display: inline-block;
	width: 18px;
	height: 18px;
	top: 1px;
	left: 14px;
	border-radius: 50%;
	background: $cgp_white;
}

.yes + label {
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	position: absolute;
	left: 1px;
	top: 1px;
	text-align: center;
}

.yes:checked + label {
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: $cgp_white;
	animation-duration: 0.4s;
	animation-name: slidein;
	animation-duration: 0.4s;
	animation-duration: 0.4s;
	animation-name: slidein;
	animation-name: slidein;
	transform: translateZ(0);
}

.no + label {
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	position: absolute;
	right: 1px;
	top: 1px;
	text-align: center;
}

.no:checked + label {
	display: inline-block;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	animation-duration: 0.4s;
	animation-name: slideno;
	animation-duration: 0.4s;
	animation-duration: 0.4s;
	animation-name: slideno;
	animation-name: slideno;
	transform: translateZ(0);
	background: $cgp_white;
}

@keyframes slidein {
	from {
		transform: translate(10px, 0);
		transform: translate(10px, 0);
	}

	to {
		transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes slidein {
	from {
		transform: translate(10px, 0);
		transform: translate(10px, 0);
	}

	to {
		transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes slideno {
	from {
		transform: translate(-10px, 0);
		transform: translate(-10px, 0);
	}

	to {
		transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes slideno {
	from {
		transform: translate(-10px, 0);
		transform: translate(-10px, 0);
	}

	to {
		transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes returnLeft {
	from {
		transform: translate(-10px, 0);
		transform: translate(-10px, 0);
	}

	to {
		transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes returnLeft {
	from {
		transform: translate(-10px, 0);
		transform: translate(-10px, 0);
	}

	to {
		transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes returnRight {
	from {
		transform: translate(10px, 0);
		transform: translate(10px, 0);
	}

	to {
		transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

@keyframes returnRight {
	from {
		transform: translate(10px, 0);
		transform: translate(10px, 0);
	}

	to {
		transform: translate(0, 0);
		transform: translate(0, 0);
	}
}

.switch-box {
	display: block;
	margin: 27px 0 30px;
}

.switch-box .switch-button {
	font-size: 12px;
}

.switch-box .switch-button > div {
	display: inline-block;
}

.switch-box .switch-title {
	margin-bottom: 22px;
	font-size: 15px;
}

.switch-box .switch-left-label {
	margin-right: 5px;
}

.switch-box .switch-right-label {
	margin-left: 8px;
}

.switch-box .switched {
	color: $cgp_primary;
}

#price-range-box {
	padding: 30px 0 20px;
}

#price-range-box .price-range-title {
	margin-bottom: 3px;
	font-size: 15px;
}

input.switch:empty {
	margin-left: -999999px;
}

input.switch:empty ~ label {
	position: relative;
	float: left;
	line-height: 1.6em;
	text-indent: 4em;
	margin: 0.2em 0;
	cursor: pointer;
	user-select: none;
	user-select: none;
	user-select: none;
	user-select: none;
}

input.switch:empty ~ label::before,
input.switch:empty ~ label::after {
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	content: ' ';
	width: 3.6em;
	background-color: $cgp_gray;
	border-radius: 1.8em;
	transition: all 100ms ease-in;
	transition: all 100ms ease-in;
}

input.switch:empty ~ label::after {
	width: 1.4em;
	top: 0.1em;
	bottom: 0.1em;
	margin-left: 0.1em;
	background-color: $cgp_white;
	border-radius: 0.7em;
}

input.switch:checked ~ label::before {
	background-color: $cgp_primary;
}

input.switch:checked ~ label::after {
	margin-left: 2.1em;
}

/* 768 */
@media screen and (max-width: 767px) {
	.carousel-caption .oglas hr {display: none;}
}


// Faker for car filters
$small_faker_height: 28px;

.faker.faker--small-white {
	height: $small_faker_height;

	// ##################################################

	&:hover, &.open {

		.drop-value { border-color: $cgp_primary; }

	}

	// ##################################################

	.drop-value {
		color: $cgp_primary !important;
		border-color: $cgp_white;
		background-color: $cgp_white;
		height: $small_faker_height;
		max-height: none;
		min-height: auto;
		padding: 0 20px;
		font-size: 12px;
		font-weight: 400;

		@include flexbox;
		@include align-items(center);
		@include transition(border-color 150ms ease-in);
	}

	// ##################################################

	.drop-handle {
		background-size: 14px auto;
		margin-top: -3px;
	}

	// ##################################################

	.drop-selection {
		top: $small_faker_height;

		div {
			font-size: 12px;

			&.active, &:hover { background-color: $cgp_primary !important; }

		}

	}


	// ##################################################

	&.open .drop-selection {
		top: #{$small_faker_height - 1};
		border-radius: 4px;
		border: 1px solid $cgp_primary;
	}

	// ##################################################

}


/* Ion.RangeSlider
// css version 2.0.3
// © 2013-2014 Denis Ineshin | IonDen.com
// =================================================================================================================== */


/* =====================================================================================================================
// RangeSlider */

.irs {
	position: relative;
	display: block;
	-webkit-touch-callout: none;
	user-select: none;
	user-select: none;
	user-select: none;
	user-select: none;
	user-select: none;
}

.irs-line {
	position: relative;
	display: block;
	overflow: hidden;
	outline: none !important;
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
	position: absolute;
	display: block;
	top: 0;
}

.irs-line-left {
	left: 0;
	width: 11%;
}

.irs-line-mid {
	left: 9%;
	width: 82%;
}

.irs-line-right {
	right: 0;
	width: 11%;
}

.irs-bar {
	position: absolute;
	display: block;
	left: 0;
	width: 0;
}

.irs-bar-edge {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
}

.irs-shadow {
	position: absolute;
	display: none;
	left: 0;
	width: 0;
}

.irs-slider {
	position: absolute;
	display: block;
	cursor: default;
	z-index: 1;
}

.irs-slider.type_last {
	z-index: 2;
}

.irs-min {
	position: absolute;
	display: block;
	left: 0;
	cursor: default;
}

.irs-max {
	position: absolute;
	display: block;
	right: 0;
	cursor: default;
}

.irs-from,
.irs-to,
.irs-single {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	cursor: default;
	white-space: nowrap;
}

.irs-grid {
	position: absolute;
	display: none;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 20px;
}

.irs-with-grid .irs-grid {
	display: block;
}

.irs-grid-pol {
	position: absolute;
	top: 0;
	left: 0;
	width: 1px;
	height: 8px;
	background: $cgp_black;
}

.irs-grid-pol.small {
	height: 4px;
}

.irs-grid-text {
	position: absolute;
	bottom: 0;
	left: 0;
	white-space: nowrap;
	text-align: center;
	font-size: 9px;
	line-height: 9px;
	padding: 0 3px;
	color: $cgp_black;
}

.irs-disable-mask {
	position: absolute;
	display: block;
	top: 0;
	left: -1%;
	width: 102%;
	height: 100%;
	cursor: default;
	background: transparent;
	z-index: 2;
}

.irs-disabled {
	opacity: 0.4;
}

.lt-ie9 .irs-disabled {
	filter: alpha(opacity=40);
}

.irs-hidden-input {
	position: absolute !important;
	display: block !important;
	top: 0 !important;
	left: 0 !important;
	width: 0 !important;
	height: 0 !important;
	font-size: 0 !important;
	line-height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	outline: none !important;
	z-index: -9999 !important;
	background: none !important;
	border-style: solid !important;
	border-color: transparent !important;
}


/* Ion.RangeSlider, Simple Skin
// css version 2.0.3
// © Denis Ineshin, 2014    https://github.com/IonDen
// © guybowden, 2014        https://github.com/guybowden
// =================================================================================================================== */


/* =====================================================================================================================
// Skin details */

.irs {
	height: 55px;
}


/* .irs-with-grid {
		height: 75px;
} */

.irs-line {
	height: 5px;
	top: 33px;
	background: $cgp_gray;
	border: 1px solid $cgp_gray_light;
	border-radius: 16px;
	border-radius: 16px;
}

.irs-line-left {
	height: 4px;
}

.irs-line-mid {
	height: 4px;
}

.irs-line-right {
	height: 4px;
}

.irs-bar {
	height: 5px;
	top: 33px;
	border-top: 1px solid $cgp_primary;
	border-bottom: 1px solid $cgp_primary;
	background: $cgp_primary;
}

.irs-bar-edge {
	height: 5px;
	top: 33px;
	width: 14px;
	border: 1px solid #428bca;
	border-right: 0;
	background: $cgp_primary;
	border-radius: 16px 0 0 16px;
	border-radius: 16px 0 0 16px;
}

.irs-shadow {
	height: 2px;
	top: 38px;
	background: $cgp_black;
	opacity: 0.3;
	border-radius: 5px;
	border-radius: 5px;
}

.lt-ie9 .irs-shadow {
	filter: alpha(opacity=30);
}

.irs-slider {
	top: 25px;
	width: 20px;
	height: 20px;
	background: $cgp_primary;
	border-radius: 20px;
	border-radius: 20px;
	cursor: pointer;
}

.irs-slider.state_hover,
.irs-slider:hover {
	background: $cgp_primary;
}

.irs-min,
.irs-max {
	/* color: $temno-siva;
		font-size: 12px; line-height: 1.333;
		text-shadow: none;
		top: 0;
		padding: 1px 5px;
		//background: rgba(0,0,0,0.1);
		border-radius: 3px;
		-moz-border-radius: 3px; */
	display: none;
}

.irs-from,
.irs-to,
.irs-single {
	color: $cgp_black;
	font-size: 14px;
	line-height: 1.333;
	text-shadow: none;
	font-weight: bold;
	padding: 1px 5px;
	border-radius: 3px;
	border-radius: 3px;
}

.lt-ie9 .irs-from,
.lt-ie9 .irs-to,
.lt-ie9 .irs-single {
	background: $cgp_primary;
}


/* PAGE CONTENT */

#main section {
	margin-top: 30px;
}

#main article h2 {
	font-size: 15px;
	margin: 0;
	padding: 15px 0;
}

#main article p + p {
	margin: 0;
	padding: 0 0 5px;
}

#main article .dodatna-oprema > div {
	border-top: 1px solid #ececec;
}

#main .model-content ul {padding-left: 0;}

#main .model-content ul li {
	position: relative;
	padding-left: 20px;
}

#main .model-content ul li::before {
	content: "";
	display: block;
	position: absolute;
	left: 0;
	top: 0.5em;
	width: 4px;
	height: 4px;
	background-color: $cgp_primary;
	border-radius: 100%;
	border-radius: 100%;
	border-radius: 100%;
	border-radius: 100%;
	border-radius: 100%;
}


/* Izrani model */

#selected-model .selected-back {
	color: $cgp_white;
	text-align: center;
	background-color: $cgp_gray_button_secondary;
	line-height: 20px;
	height: 60px;
	padding: 10px;
	margin: 0 20px 30px 0;
	box-sizing: border-box;
}

#selected-model .selected-back,
#selected-model .selected-heading {
	display: inline-block;
	vertical-align: middle;
}

#selected-model .selected-heading {
	font-size: 60px;
	font-weight: 200;
	margin-bottom: 30px;
	color: #D0CFCF;
}

.model-dobavljivo {
	color: $cgp_white;
	background-color: $cgp_primary;
	border-radius: 4px;
	height: 22px;
	padding: 0 6px;
	font-size: 10px;
	text-transform: uppercase;
	@include flexbox;
	@include align-items(center);
}

.car-model {
	text-align: center;
	border: 1px solid #ececec;
	margin-bottom: 30px;

    .model-dobavljivo {
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
}

.car-model .model-img {
	border-bottom: 1px solid #ececec;
	position: relative;
}

.car-model .model-img .icon-heart-o,
.car-model .model-img .icon-heart {
	float: right;
	margin: 10px 15px 0 0;
}

.car-model .model-img img {
	width: 180px;
	margin: 10px auto;
}

.car-model .selected {
	background-color: #ececec;
}

.model-content {
	text-align: left;
	padding: 15px 10px;
	box-sizing: border-box;
	font-size: 15px;

	.reservation-list {
		color: $cgp_white;
		background-color: $cgp_primary;
		padding: 0 6px;
		@include flexbox;

		height: 28px;
		font-size: 15px;
		font-weight: 800;
		align-items: center;
		justify-content: center;
		margin: 0 10px;
		margin-top: 10px;

		&.reserved {
			background-color: $cgp_gray;
		}
	}
}

.model-content h1 {
	font-weight: 700;
	font-size: 24px;
	line-height: 1.2em;
	margin-bottom: 0.1em;
}

.model-content h1 + p {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 30px;
}

.model-content ul li {
	font-size: 12px;
	line-height: 1.5em;
}

.model-content ul li {margin-bottom: 10px;}

.model-content ul li span {
	font-size: inherit;
	font-weight: 700;
	line-height: inherit;
}

.model-content ul li span + span {
	font-weight: 400;
}

.model-content mark {
	background-color: rgba(255, 255, 255, 0);
	font-weight: bold;
	font-size: 20px;
	margin: 0;
	padding: 0;
}

/* .model-content ul {
	padding: 0 0 0 15px;
} */

.model-content li {
	color: $cgp_primary;
}

.model-content li span {
	color: $cgp_black;
}

.model-content .most-viewed > strong,
.model-content .most-viewed > mark,
.model-content .most-viewed-highlight > strong,
.model-content .most-viewed-highlight > mark {
	display: block;
	width: 50%;
	float: left;
	padding: 20px 0;
	line-height: 10px;
}

.model-content .most-viewed-highlight > mark {
	text-align: center;
}

.selected-most-viewed-title {
	text-align: center;
	font-size: 25px;
	font-weight: 200;
	margin-bottom: 30px;

  .cta-buttons {
    @include flexbox;
    @include justify-content(space-around);
    @include flex-wrap(wrap);

    .btn {
      color: $cgp_white;
      background-color: $cgp_primary;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: 400;
      padding: 10px 20px;
      border-radius: 0;
      margin-bottom: 10px;
    }
  }
}

.bordered {
	border: 1px solid #ececec;
}

.bordered .selected-most-viewed-img {
	height: 360px;
	margin: 20px 0;
}

.bordered .model-content {
	background-color: #ececec;
	min-height: 360px;
	border: 1px solid #ececec;
}

.bordered .model-content .most-viewed-highlight {
	/* padding-bottom: 30px; */
	border: 1px solid #ececec;
	overflow: hidden;
}

#most-deliverable-title,
#most-deliverable-title-m {
	color: #d0cfcf;
	font-weight: 200;
	font-size: 70px;
	line-height: 1em;
	margin-bottom: 0.3em;
	text-align: center;

	/* margin-bottom: 45px; */
}

#most-deliverable,
#most-deliverable-m {text-align: left;}

#most-deliverable > li,
#most-deliverable-m > li {
	display: inline-block;
	margin: 5px 20px 20px;
	text-align: center;
}

#most-deliverable > li img,
#most-deliverable-m > li img {
	height: auto;
	max-width: 148px;
	width: 100%;
}

#most-deliverable > li p,
#most-deliverable-m > li p {
	font-size: 16px;
	font-weight: 700;
	line-height: 1.625em;
}

#most-deliverable > li p span,
#most-deliverable-m > li p span {
	font-weight: inherit;
}

#most-deliverable .not-available img,
#most-deliverable-m .not-available img {

	/* Current draft standard */

	/* New WebKit */
	filter: grayscale(100%);
	filter: grayscale(100%);
	filter: grayscale(100%);

	/* Not yet supported in Gecko, Opera or IE */

	/* IE */
	filter: grayscale(1);

	/* Old WebKit */
	opacity: 0.25;
	filter: alpha(opacity=25);
}

#most-deliverable .not-available p,
#most-deliverable-m .not-available p,
#most-deliverable .not-available .green,
#most-deliverable-m .not-available .green {
	color: $cgp_gray !important;
}

#most-viewed-title {
	font-size: 30px;
	font-weight: 400;
	margin-bottom: 45px;
	text-align: center;
}

.most-viewed-img,
.most-viewed-sub-img {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.most-viewed-img {
	width: 100%;
	height: 225px;
}

.most-viewed-img .most-viewed-sub-img {
	width: 100px;
	height: 100px;
	line-height: 100px;
	border-radius: 50%;
	right: 30px;
	top: 110px;
	position: absolute;
	background-color: $cgp_white;
}

.most-viewed-img .most-viewed-sub-img img {
	height: auto;
	position: relative;
	top: 50%;
	width: 100%;
	transform: translate(0, -50%);
	transform: translate(0, -50%);
	transform: translate(0, -50%);
	transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.most-viewed .model-content {
	background-color: #ececec;
	padding: 0;
	width: 100%;
	box-sizing: border-box;
}

.most-viewed .model-content > .model-padding {padding: 15px 10px;}

.most-viewed .most-viewed-bottom {
	background-color: $cgp_black;
	clear: both;
	color: $cgp_white;
	padding: 5px 15px;
}

.most-viewed .most-viewed-bottom .glyphicon-menu-up {
	float: right;
	margin-top: 5px;
}

.most-viewed-assets > div {
	background-color: $cgp_black;
	color: $cgp_white;
	padding: 20px;
	line-height: 20px;
	padding-top: 5px;
}

.most-viewed-assets strong {
	line-height: 30px;
}

.most-viewed-assets ul {
	padding: 0 0 0 15px;
}

.most-viewed-assets li {
	color: $cgp_primary;
}

.most-viewed-assets li span {
	color: $cgp_white;
}

/* Responsive */


/* 3-COLUMN TEXT */

.column-text {
	column-count: 3;
	column-count: 3;
	column-count: 3;
	column-gap: 40px;
	column-gap: 40px;
	column-gap: 40px;
}

.google-maps {
	width: 100%;
	height: 400px;
}


/* ASIDE */

aside {
	text-align: right;
}

aside a,
aside a:active,
aside a:hover,
aside a:focus {
	outline: 0;
	border: 0;
}

aside img {
	display: initial;
	margin-bottom: 10px;
	margin-right: 1px;
	max-width: 100%;
	width: 100%;
}

/* FOOTER */

footer a,
footer a:active,
footer a:hover,
footer a:focus {
	outline: 0;
	border: 0;
}

footer ul {
	margin: 30px 0 0;
	padding: 0;
	color: $cgp_gray;
}

footer ul li {
	margin: 0;
	padding: 0;
	white-space: nowrap;
	display: inline-block;
	box-sizing: border-box;
}

footer ul li + li::before {
	content: "|";
	padding: 0 5px;
}

footer a:link,
footer a:active,
footer a:visited,
footer a:hover {
	font-size: 11px;
	text-decoration: none;
	color: $cgp_gray;
}

.footer-left {
	text-align: left;
}

.footer-right {
	text-align: right;
}


/* Modal */

.modal-content,
.modal input {
	border-radius: 0;
}

.modal-header {
	padding-bottom: 10px;
}

.modal-body {
	padding-left: 25px;
	padding-right: 25px;
	font-size: 15px;
}

.modal-body hr {
	border-color: $cgp_gray;
}

.modal-body a {
	color: $cgp_primary;
}

.modal-footer {
	padding: 0 25px 25px;
}

.modal .close span {
	font-size: 50px;
	line-height: 30px;
	font-weight: 200;
}

.modal .close,
.modal .close:hover,
.modal .close:focus {
	opacity: 1;
}

/* .modal h4 {
	text-transform: uppercase;
} */

#pick-seller {
	/* .dropdown button > span {
			text-align: right;
	} */
}

#pick-seller .white-button {
	width: 100%;
	background-color: $cgp_white;
	border-color: $cgp_gray;
	color: $cgp_gray;
}

#pick-seller .white-button:hover,
#pick-seller .white-button:active {
	background-color: #ececec;
	border-color: #ececec;
	color: $cgp_gray;
}

#pick-seller .dropdown {
	margin-bottom: 10px;
}

#pick-seller .dropdown button {
	text-align: left;
}

#pick-seller .dropdown-menu {
	width: 100%;
}

#pick-seller #picked-content {
	/*  display: none; */
	margin-top: 20px;
}


/* MEDIA QUERY */

@media (max-width: 1200px) {
	/* slider */
	#slider {
		background-color: #0f0;
	}
}

@media (min-width: 767px) and (max-width: 1337px) {
	#selected-model .selected-heading {
		font-size: 60px;
	}

	footer ul {
		list-style-type: none;
	}

	footer ul li {
		/* display: list-item; */
	}

	/* footer ul li + li::before {
		content: "";
		padding: 0; } */
}

@media (max-width: 991px) {
	/* 3-COLUMN TEXT */
	.column-text {
		column-count: 2;
		column-count: 2;
		column-count: 2;
		column-gap: 40px;
		column-gap: 40px;
		column-gap: 40px;
	}

	#selected-model .selected-heading {
		font-size: 50px;
	}

	.most-viewed-assets > div {
		margin-top: 30px;
	}

	.switch-box,
	.price-range-box {
		margin: 20px;
	}

	.switch-box {
		margin: 0;
		padding: 20px 0 30px;
	}

	.switch-title {
		margin-bottom: 5px !important;
	}

	#switch-title-3 {
		margin-bottom: 0 !important;
	}

	#most-deliverable {text-align: center;}
	#most-deliverable-m {text-align: center;}

}

@media (max-width: 768px) {
	/* seznam želja */
	#top-button .car {
		display: block;
	}

	.carousel-caption .oglas {
		text-align: right;
	}

	#carousel-counter {
		inset: 15px auto auto 15px;
		height: 38px;
		width: 38px;
		line-height: 36px;
	}

	/* 3-COLUMN TEXT */
	.column-text {
		column-count: 1;

		/* Chrome, Safari, Opera */
		column-count: 1;

		/* Firefox */
		column-count: 1;
		column-gap: 0;

		/* Chrome, Safari, Opera */
		column-gap: 0;

		/* Firefox */
		column-gap: 0;
	}

	#selected-model .selected-heading {
		font-size: 30px;
	}

	.model-content .most-viewed-highlight > mark {
		text-align: left;
	}

	#most-deliverable-title,
	#most-deliverable-title-m {
		font-size: 50px;
	}

	.most-viewed {
		margin-bottom: 30px;
	}

	.switch-box {
		margin: 0;
		padding: 0 0 30px;
	}

	/* ASIDE */
	aside img {
		display: block;
	}

	.footer-right {
		text-align: left;
	}
}

@media (max-width: 767px) and (max-width: 560px) {
	#most-deliverable-title,
	#most-deliverable-title-m {
		font-size: 30px;
	}

	.footer-left ul {
		list-style-type: none;
	}

	.footer-left ul li {
		display: list-item;
	}

	.footer-left ul li + li::before {
		content: "";
		padding: 0;
	}
}

@media (max-width: 767px) and (max-width: 480px) {
	#main nav ul {
		list-style-type: none;
	}

	#main nav ul li {
		display: list-item;
	}

	aside img {
		width: 100%;
	}
}

@media (max-width: 767px) and (max-width: 449px) {
	/* top */
	#logo img,
	.top-right {
		display: block;
		text-align: left;

		/* clear: both; */
	}

	#top-button {
		border-left: 0;
	}
}

@media (max-width: 400px) {
	#logo img,
	.top-right {
		display: block;
		text-align: left;
		clear: both;
	}
}


/* COLUMN COUNT CORRECTION */
#main .car-description ul li > ul li { list-style: inside none none; }
#main .column-text li > ul li.primary { font-weight: bolder; }
