@charset "UTF-8";

//@import '../../config.json'; // Load config file

//*******************************************************************************
// X.0 - GENERAL LAYOUT
//*******************************************************************************

// GLOBAL VARS: breakpoints;
$bkp_hd: 1920px;             // Desktop HD
$bkp_xl: 1440px;             // Desktop Large
$bkp_lg: 1024px;             // Tablet landscape
$bkp_md: 767.9px;              // Tablet portrait
$bkp_sm: 375px;              // Mobile : 375px
$bkp_xs: 320px;              // Mobile : 320px

// GRID GUTTERS
// $gutter_xl:                0px;                // Desktop HD
// $gutter_lg:                0px;                // Desktop - large
// $gutter_md:                0px;                // Desktop - regular / Tablet landscape
// $gutter_sm:                0px;                // Tablet portrait
// $gutter_xs:                0px;                // Mobile

$grid-gutter-width: 30px !default;    // Bootstrap default gutter override

// CONTAINER WIDTHS
// $container_xl: 	       1440px;              // Desktop HD
// $container_lg: 	       1140px;              // Desktop - large / Tablet landscape
// $container_md:    	   960px;               // Desktop - regular
// $container_sm:     	   704px;               // Tablet - portrait
// $container_xs:     	   calc(100% - 48px);   // Mobile

// Bootstrap breakpoints override
$grid-breakpoints: (
	xs: 0,                                      // default: 0
	sm: 544px,                                // default: 576px
	md: 768px,                                // default: 768px
	lg: 992px,                                // default: 992px
	xl: 1450px                                 // default: 1200px
) !default;

// Bootstrap container override
$container-max-widths: (
	xs: 100%,                               // default: 320px
	sm: 576px,                               // default: 540px
	md: 750px,                               // default: 720px
	lg: 100%,                               // default: 960px
	xl: 1440px                               // default: 1140px
) !default;

// Bootstrap overrides
$grid-columns: 12 !default;      // default: 12
$grid-gutter-width: 32px !default;    // default: 30px
$paragraph-margin-bottom: 0 !default;       // default: 1rem


//*******************************************************************************
// X.1 - INCLUDES: NPM files
//*******************************************************************************

@import "../../node_modules/angularjs-slider/dist/rzslider";
/* stylelint-disable-next-line scss/load-partial-extension */
@import "../../node_modules/slick-carousel/slick/slick.scss";
/* stylelint-disable-next-line scss/load-partial-extension */
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../node_modules/angular-toastr/dist/angular-toastr";

//@import "../../node_modules/bootstrap/scss/bootstrap";

// Core variables and mixins
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Reset and dependencies
@import "../../node_modules/bootstrap/scss/normalize";
@import "../../node_modules/bootstrap/scss/print";

// Core CSS
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";

// Components
@import "../../node_modules/bootstrap/scss/animation";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/pager";
@import "../../node_modules/bootstrap/scss/labels";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/responsive-embed";
@import "../../node_modules/bootstrap/scss/close";

// Components w/ JavaScript
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";

//@import "../../node_modules/bootstrap/scss/carousel";

// Utility classes
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/utilities-background";
@import "../../node_modules/bootstrap/scss/utilities-spacing";
@import "../../node_modules/bootstrap/scss/utilities-responsive";

//.container { max-width: none; }

//*******************************************************************************
// X.2 - INCLUDES: Website
//*******************************************************************************

//@import "base/reset";                       // Reset - DO NOT DELETE ANY ENTRIES
@import "mixins/mixins";                    // Mixins - DO NOT DELETE ANY ENTRIES
@import "mixins/functions";

// Core stuff
@import "base/variables";
@import "base/icons";
@import "base/webfonts";

// Page specific
@import "include/bootstrap";
@import "include/style_old";
@import "include/landing";
@import "include/paket";
@import "include/common";

@import "include/most_viewed_models";
