/*******************************************************************************
* Fonts
*******************************************************************************/

@font-face {
    font-family: 'SkodaNext';
    src: url("../fonts/SKODANextW05-Light.eot");
    src: url("../fonts/SKODANextW05-Light.eot?#iefix") format("embedded-opentype"),
        url("../fonts/SKODANextW05-Light.woff") format("woff"),
        url("../fonts/SKODANextW05-Light.ttf") format("truetype"),
        url("../fonts/SKODANextW05-Light.svg") format("svg");
    font-style: normal;
    font-weight: 200;
}


@font-face {
    font-family: 'SkodaNext';
    src: url("../fonts/SKODANextW05-Regular.eot");
    src: url("../fonts/SKODANextW05-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/SKODANextW05-Regular.woff") format("woff"),
        url("../fonts/SKODANextW05-Regular.ttf") format("truetype"),
        url("../fonts/SKODANextW05-Regular.svg") format("svg");
    font-style: normal;
    font-weight: 400;
}


@font-face {
    font-family: 'SkodaNext';
    src: url("../fonts/SKODANextW05-Italic.eot");
    src: url("../fonts/SKODANextW05-Italic.eot?#iefix") format("embedded-opentype"),
        url("../fonts/SKODANextW05-Italic.woff") format("woff"),
        url("../fonts/SKODANextW05-Italic.ttf") format("truetype"),
        url("../fonts/SKODANextW05-Italic.svg") format("svg");
    font-style: italic;
    font-weight: 400;
}


@font-face {
    font-family: 'SkodaNext';
    src: url("../fonts/SKODANextW05-Bold.eot");
    src: url("../fonts/SKODANextW05-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/SKODANextW05-Bold.woff") format("woff"),
        url("../fonts/SKODANextW05-Bold.ttf") format("truetype"),
        url("../fonts/SKODANextW05-Bold.svg#62a825e13fa19ce06305474af0a11c2c") format("svg");
    font-style: normal;
    font-weight: 700;
}
