// ENCODER for base64 images:
// http://yoksel.github.io/url-encoder/

// SVG Optimizer:
// https://jakearchibald.github.io/svgomg/


//*******************************************************************************
// 1.0 - ICONS - Icons
//*******************************************************************************

// icons/icon_checkbox.svg
$icon_checkbox: "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3E%3Cpath d='M5.35 14.81l-5-4.9A.89.89 0 0 1 0 9.24a1 1 0 0 1 .26-.71.88.88 0 0 1 .67-.3 1 1 0 0 1 .69.26l4.17 4.08L13.39.41A.93.93 0 0 1 14 0a.85.85 0 0 1 .71.13.93.93 0 0 1 .41.6 1 1 0 0 1-.13.73L6.77 14.64l-.06.1-.15.11a.93.93 0 0 1-.3.13h-.14a.27.27 0 0 1-.22 0h-.16a.48.48 0 0 0-.17-.08l-.13-.05-.06-.06z' fill='%23#{color-without-hash($cgp_primary)}'/%3E%3C/svg%3E";

// icons/icon_arrow_down_small.svg
$icon_arrow_down_small: "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8' width='14' height='8'%3E%3Cg fill='%23#{color-without-hash($cgp_primary)}'%3E%3Cpath d='M6.92 7.63L0 .71.71 0l6.21 6.22L13.14 0l.71.71-6.93 6.92z'/%3E%3C/g%3E%3C/svg%3E";
