//*******************************************************************************
// X.X - FUNCTIONS
//*******************************************************************************

// ##################################################
// Calculate real viewport height (iOS compatible)
// Example: height: calcViewportHeight(75);

@function calcViewportHeight($height:100) {
	@return calc(var(--vh, 1vh) * #{$height});
}

// ##################################################

@function str-replace($string, $search, $replace: '') {
$index: str-index($string, $search);

@if $index {
	@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
}

@return $string;
}


// Returns color withouth the hashtag in front
@function color-without-hash($color) {
	$stringed_color: quote('#{$color}');
	$sliced_string: str-replace($stringed_color, '#', '');

	@return $sliced_string;
}
